.help-hero {
  background-color: var(--primary-color);
}
.help-hero-container {
  width: 1250px;
  margin: auto;
  display: flex;
  height: 1000px;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 100px;
}
.help-hero-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #9e9e9e;
  text-align: center;
  height: 384px;
  width: 837px;
  margin: 0 auto;
  padding-top: 200px;
}
.help-hero-text h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #fafafa;
  width: 547px;
  margin: 0 auto;
  padding-bottom: 25px;
}

.help-hero-text p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.3px;
}
.help-hero-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.help-hero-icons div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin: 60px 36px 0 0;
  border: 1.5px solid white;
  border-radius: 50%;
}
.help-hero-icon {
  font-size: 31px;
  color: #fafafa;
  align-self: center;
}
.help-hero-icons div:hover .help-hero-icon {
  color: black;
  transition: all 200ms ease;
}
.help-hero-icons div:hover {
  background-color: white;
  transition: all 200ms ease;
}

.help-faq-container {
  max-width: 1250px;
  margin: 0 auto;
  padding: 140px 0;
}
.help-faq-container-text {
  width: 858px;
  margin: 0 auto;
  text-align: center;
}
.help-faq-container-text h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -0.3px;
  color: #121212;
  padding-bottom: 24px;
}
.help-faq-container-text-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.3px;
  color: #9e9e9e;
  padding-bottom: 50px;
}
.accordion-label-container {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  cursor: pointer;
}
.mantine-Accordion-label {
  /* width: 341px; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: black;
  /* padding: 7px 0; */
}
.mantine-Accordion-contentInner {
  width: 867px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #757575;
  transition: all 200ms ease;
}

.slide-in-top {
  animation: slide-in-top 400ms ease both;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-60px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* @keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
} */

/* Responsive */

@media screen and (max-width: 1450px) {
  .help-hero-container {
    width: 90%;
    margin: auto;
  }

  .help-hero-text {
    height: auto;
    margin: 0 auto;
  }
  .help-hero-text h1 {
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    width: 547px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1050px) {
  .help-hero-text {
    text-align: center;
    height: auto;
    width: 100%;
    margin: 0 auto;
  }

  .help-hero-text h1 {
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    width: 547px;
    margin: 0 auto;
  }

  .help-hero-icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .help-hero-icons div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin: 48px 36px 0 0;
    border: 1.5px solid white;
    border-radius: 50%;
  }

  .help-hero-icon {
    font-size: 31px;
    color: #fafafa;
    align-self: center;
  }

  .help-hero-icons div:hover .help-hero-icon {
    color: black;
  }

  .help-hero-icons div:hover {
    background-color: white;
  }

  .help-faq-container {
    width: 90%;
    margin: 0 auto;
    padding: 140px 0;
  }

  .help-faq-container-text {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .help-faq-container-text h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: -0.3px;
    color: #121212;
    padding-bottom: 24px;
  }

  .help-faq-container-text p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.3px;
    color: #9e9e9e;
    padding-bottom: 50px;
  }

  .mantine-Accordion-label {
    width: 341px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.3px;

    padding: 7px 0;
  }

  .mantine-Accordion-contentInner {
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.3px;
  }
}

@media screen and (max-width: 768px) {
  .help-hero-container {
    max-width: 1250px;
    height: auto;
    margin: 0 auto;
  }
  .help-hero-text {
    padding-top: 100px;
  }
  .help-hero-text h1 {
    font-size: 32px;
    line-height: 48px;
    width: 90%;
  }
  .help-hero-text p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .help-hero-icons div {
    width: 60px;
    height: 60px;
    margin: 40px 10px 0 0;
    transform: scale(0.9);
  }

  .help-faq-container {
    width: 90%;
    margin: 0 auto;
    padding: 80px 0;
  }
  .help-faq-container-text h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.3px;
    color: #121212;
    padding-bottom: 18px;
  }

  .help-faq-container-text p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.3px;

    color: #757575;
    padding-bottom: 35px;
  }
  .accordion-label-container {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
  }

  .mantine-Accordion-label {
    width: 100%;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.3px;
    color: black;
    padding: 7px 0;
  }

  .mantine-Accordion-contentInner {
    width: 100%;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.3px;
    color: #757575;
  }
}

@media screen and (max-width: 550px) {
  .help-hero-text {
    padding-top: 100px;
  }
  .help-hero-text h1 {
    font-size: 30px;
  }

  .help-hero-text p {
    font-size: 12px;
    line-height: 24px;
    text-align: center;
  }
  .help-hero-icons div {
    width: 50px;
    height: 50px;
  }

  .help-hero-icons div {
    margin-top: 25px;
  }

  .help-hero-icons div svg {
    font-size: 25px;
  }

  .help-faq-container-text h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.3px;
    color: #121212;
    padding-bottom: 18px;
  }

  .help-faq-container-text p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #9e9e9e;
    padding-bottom: 35px;
  }

  .mantine-Accordion-label {
    width: 100%;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.3px;

    padding: 7px 0;
  }

  .mantine-Accordion-contentInner {
    width: 100%;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.3px;
  }
}

@media screen and (max-height: 700px) {
  .help-hero-text {
    padding-top: 100px;
  }
  .help-hero-container {
    padding-bottom: 100px;
  }
}
