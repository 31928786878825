.Pricing {
  z-index: 10;
  position: relative;
  background: #fff;
}

.Pricing-container {
  width: 1250px;
  margin: auto;
}

.Pricing-header {
  color: var(--primary-color);
  text-align: center;
  margin: 0 auto;
  font-size: 36px;
  font-weight: var(--semi-bold);
  padding-bottom: 80px;
}

.Pricing-information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 80px;
}

.Pricing-price,
.Pricing-try,
.Pricing-clickable {
  width: 49%;
  height: 540px;
  border-radius: 30px;
  padding: 50px;
}

.Pricing-price-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Pricing-clickable:hover {
  cursor: pointer;
}

/* LEFT SIDE */

.Pricing-price {
  background-color: var(--secondary-color);
  color: var(--button-text);
  font-weight: var(--medium);
  position: relative;
}
.Pricing-clickable {
  background-color: var(--secondary-color);
  color: var(--button-text);
  font-weight: var(--medium);
  position: relative;
}

.Pricing-price p {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.3px;
  text-align: left;
}
.Pricing-clickable p {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.3px;
  text-align: left;
}
.Pricing-price .small {
  font-size: 20px;
}
.Pricing-clickable .small {
  font-size: 20px;
}

.Pricing .Amount {
  font-size: 48px;
  line-height: 72px;
  margin-top: 40px;
  font-weight: var(--semi-bold);
  text-align: center;
}

.Pricing-price-content {
  width: 462px;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;
  font-size: 18px;
  line-height: 27px;
  font-weight: var(--medium);
}

.Pricing-price-why {
  margin-top: 60px;
  font-size: 24px;
  line-height: 36px;
  text-decoration: 1px underline;
  text-align: center;
}
.Pricing-price-why:hover {
  cursor: pointer;
}

/* RIGHT-SIDE */

.Pricing-try {
  background-color: var(--light-green);
  position: relative;
}

.Pricing-try p {
  color: var(--primry-color);
  text-align: left;
  font-size: 24px;
  line-height: 36px;
  font-weight: var(--medium);
  letter-spacing: -0.3px;
}

.Pricing-try-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.3px;

  /* regular text */

  color: #9e9e9e;
}

.Pricing-display {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.Pricing-Price-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.3px;
  margin: 0px 25.25px;

  color: #ffffff;
}

.x-size {
  font-size: 24px;
}

/* WHY ISNT TRAKKA FREE ANIM */

.move-up {
  margin-top: 0px;
  line-height: 36px;
  text-decoration: 1px underline;
  text-align: center;
  transform: translateY(-210px);
  transition: 500ms ease;
  cursor: default !important;
}
.move-down {
  margin-top: 0px;
  line-height: 36px;
  text-decoration: 1px underline;
  text-align: center;
  transform: translateY(60px);
  transition: 500ms ease;
}

.fade-out {
  color: var(--secondary-color);
  transition: 0ms ease;
}
.fade-in {
  color: #fff;
  transition: 0ms ease;
}
.Amount {
  padding-bottom: 40px;
}

.why-hidden {
  display: none;
  color: var(--secondary-color);
  position: absolute;
}

.why-visible {
  display: block;
  position: absolute;
  padding-right: 50px;
  color: var(--secondary-color);
  animation-name: show-why;
  animation-direction: linear;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  transform: translateY(-150px);
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  letter-spacing: -0.3px;
  position: absolute;

  color: #ffffff;
}

.original-x {
  display: none;
}

.Pricing-price-x {
  cursor: pointer;
  animation-name: hide-x;
  animation-direction: linear;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
}

.Pricing-price-x-show {
  display: block;
  cursor: pointer;
  animation-name: show-x;
  animation-direction: linear;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-duration: 750ms;
}

@keyframes show-why {
  0% {
    color: var(--secondary-color);
  }
  50% {
    color: var(--secondary-color);
  }
  100% {
    color: white;
  }
}
@keyframes show-x {
  0% {
    color: var(--secondary-color);
  }
  50% {
    color: var(--secondary-color);
  }
  100% {
    color: white;
  }
}
@keyframes hide-x {
  0% {
    color: white;
  }
  50% {
    color: var(--secondary-color);
  }
  100% {
    color: var(--secondary-color);
    display: none;
    cursor: unset;
  }
}

.fade-in {
  -webkit-animation: fade-in 500ms 250ms both;
  animation: fade-in 500ms 250ms both;
}

.fade-in2 {
  -webkit-animation: fade-in 500ms 500ms both;
  animation: fade-in 500ms 500ms both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* RESPONSIVENESS */
@media screen and (max-width: 1450px) {
  .Pricing-container {
    width: 90%;
    margin: auto;
  }
  .Pricing-price-content,
  .Pricing-try-content {
    width: auto;
  }

  .Pricing-header {
    color: var(--primary-color);
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
    font-size: 36px;
    font-weight: var(--semi-bold);
    text-align: center;
  }
}

@media screen and (max-width: 1030px) {
  .Pricing-header {
    font-size: 35px;
  }
}

@media screen and (max-width: 1050px) {
  .Pricing-container {
    width: 90%;
    margin: auto;
  }
  .Pricing-information {
    width: 100%;
  }
  .Track-feature-container {
    width: auto;
    flex-direction: column;
    height: auto;
  }

  .Pricing-price,
  .Pricing-try,
  .Pricing-clickable {
    height: 600px;
  }

  .Pricing-Price-content {
    margin: 0px;
  }


}
@media screen and (max-width: 850px) {
  .why-visible {
    transform: translateY(-190px);
    font-size: 15px;

  
    color: #ffffff;
  }
  .move-up {
    transform: translateY(-280px);
  }
}

@media screen and (max-width: 770px) {
  .Pricing-information {
    flex-direction: column;
  }
  .Pricing-price,
  .Pricing-try,
  .Pricing-clickable {
    width: 100%;
    margin: 20px auto;
  }

  .move-up {
    transform: translateY(-220px);
  }
  .why-visible{
    transform: translateY(-150px);
    font-size:18px;

  }
}

/* MOBILE RESPONSIVENESS */
@media screen and (max-width: 550px) {
  .Amount {
    padding-bottom: 0px;
  }
  .x-size {
    font-size: 18px;
  }

  .Pricing-container {
    width: 100%;
    padding-left: 13px;
    padding-right: 13px;
    height: auto;
    letter-spacing: -0.3px;
  }
  .Pricing-information {
    flex-direction: column;
  }
  .Pricing-header {
    width: auto;
    font-size: 26px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.3px;
    padding: 0 30px;
    padding-bottom: 60px;
  }
  .Pricing-price p,
  .Pricing-clickable p {
    font-size: 16px;
    line-height: 24px;
    color: var(--button-text);
  }
  .Pricing-price .Amount,
  .Pricing-clickable .Amount {
    font-size: 36px;
    line-height: 54px;
  }
  .Pricing-price-content {
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin-top: 20px;
  }
  .Pricing-price-why {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 50px;
  }
  .Pricing-price,
  .Pricing-try,
  .Pricing-clickable {
    height: 360px;
    border-radius: 15px;
    padding: 30px 20px;
    margin: 10px 0;
  }
  .Pricing-try p {
    font-size: 16px;
  }
  .Pricing-try-content {
    margin-top: 20px;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .Pricing-try img {
    width: 133px;
  }
  .Pricing-try-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.3px;

    /* text */

    color: #757575;
  }
  .Pricing-Price-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.3px;

    color: #ffffff;
  }

  .why-visible {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    letter-spacing: -0.3px;
    transition: 500ms ease;
    color: #ffffff;
  }

  .move-up {
    transform: translateY(-850%);
  }
  .move-down {
    transform: translateY(0px);
  }

  .why-visible {
    transform: translateY(-140px);
    padding-right: 20px;
    margin-top: 30px;
    font-weight:400;
  }
  .why-container {
    height: 70px;
  }
}

@media screen and (max-width: 510px) {
  .why-visible {
    margin-top: 30px;
  }
}

@media screen and (max-width: 480px) {
  .why-visible {
    margin-top: 0px;
  }
}

@media screen and (max-width: 402px) {
}

@media screen and (max-width: 380px) {
  .Pricing-header {
    width: auto;
    font-size: 24px;

    padding-bottom: 60px;
  }

  .why-visible {
    transform: translateY(-170px);
  }
}

@media screen and (max-width: 360px) {
  .move-up {
    transform: translateY(-900%);
  }

  .why-visible {
    transform: translateY(-180px);
    padding-right: 20px;
    font-size:12px;
  }
}

@media screen and (max-width: 340px) {
  .Pricing-header {
    width: auto;
    font-size: 22px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.3px;
    padding: 0 10px;
    padding-bottom: 40px;
  }
  .why-visible {
    font-size: 13px;
    transform: translateY(-190px);
    padding-right: 15px;
  }
}
@media screen and (max-width: 340px) {

  .why-visible {
    font-size: 12px;
    transform: translateY(-210px);

  }
}

@media screen and (max-width: 300px) {
  .why-visible {
    font-size: 11px;
    transform: translateY(-230px);
    padding-right: 15px;
  }
}
