h1 {
  color: #121212;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
}
.about-hero {
  background-color: var(--primary-color);
  color: var(--button-text);
}
.about-hero-container {
  width: 1250px;
  height: 1000px;
  margin: 0 auto;
  padding-top: 140px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

.about-hero-text {
  padding-top: 140px;
  width: 50%;
}
.about-hero-main-text {
  width: 648px;
  height: 162px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -0.3px;
  color: #fafafa;
  padding-bottom: 36px;
}
.about-hero-subtext {
  width: 666px;
  height: 352px;
  padding-top: 36px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: -0.3px;
  color: #9e9e9e;
}
.about-hero-textspace {
  margin: 20px 0;
}

.about-hero-image {
  align-self: center;
  position: relative;
  width: 471px;
  height: 473px;

  z-index: 2;
}

.about-hero-image > img {
  border-radius: 10px;
}
.about-download-icons {
  display: none;
  margin-top: 58px;
  text-align: left;
  font-size: 20px;
}
.about-download-icons div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-download-icons div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 15px;
  cursor: pointer;
  transition: all 200ms ease;
}
.about-download-icons div > div:first-child {
  border: 1.5px solid white;
  font-size: 30px;
}

.about-download-icons div > div:last-child {
  border: 1.5px solid white;
  font-size: 25px;
}
.about-download-icons div > div:hover {
  color: black;
}
.about-download-icons div > div:hover {
  background-color: white;
}

/* about-info section */
.about-info {
  width: 1250px;
  margin: 0 auto;
  padding: 140px 0;
  text-align: center;
}
.about-info > p {
  color: #9e9e9e;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  padding: 0 220px;
  padding-top: 12px;
}
.about-info-container {
  padding-top: 110px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
}
.about-info-container-inner {
  text-align: left;
}

.about-info-container-inner > div {
  width: 80px;
  height: 80px;
  background: #f4f4f4;
  border-radius: 10px;
}
.about-info-container-inner > h3 {
  color: #121212;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 34px */
  padding-top: 24px;
  letter-spacing: -0.3px;
}
.about-info-container-inner > p {
  color: #9e9e9e;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  padding-top: 12px;
  margin-bottom: 60px;
}

/* about minds section */
.about-minds {
  background-color: #f8f8f8;
  text-align: center;
}
.about-minds-container {
  margin: 0 auto;
  padding: 96px 0;
  max-width: 1250px;
}
.about-minds-container > p {
  color: #9e9e9e;
  /* width: 827px; */
  font-size: 19px;
  font-weight: 400;
  padding: 24px 13% 60px 13%;
  margin: 0 auto;
}
.about-minds-img-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  margin: 0 auto;
}

.about-minds-img-container > div {
  width: 200px;
  height: 200px;

  margin: 0 auto;
  margin-bottom: 80px;
}

.image {
  position: relative;
}

.image__img {
  display: block;
  width: 100%;
  border-radius: 50%;
  margin-bottom: 25px;
}

.image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.image__overlay--blur {
  backdrop-filter: blur(5px);
}

.image__overlay--primary {
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.75));
}

.image__overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.image__overlay:hover {
  opacity: 1;
}

.image__overlay:hover > * {
  transform: translateY(0);
}

.image__title {
  text-align: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;

  line-height: 21px;
  letter-spacing: -0.3px;
  margin-bottom: 4px;
}

.image__description {
  text-align: center;

  font-family: 'Poppins';
  font-style: normal;
  margin-top: 2px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: -0.3px;
  color: #757575;
}

/* Responsive */

@media screen and (max-width: 1450px) {
  .about-hero-container {
    width: 90%;
  }

  .about-hero {
  }

  .about-hero-main-text {
    width: 100%;
    height: auto;
  }
  .about-hero-subtext {
    padding-top: 0;
    width: 100%;
    height: auto;
  }
  .about-info {
    width: 90%;
    padding: 100px 0;
  }
}

@media screen and (max-width: 1050px) {
  .about-hero-container {
    flex-direction: column;
    width: 90%;
    margin: auto;
    height: auto;
    padding-top: 100px;
  }
  .about-hero-image {
    display: none;
  }
  .about-hero-text {
    width: 100%;
    padding-top: 100px;
  }

  .about-minds-img-container {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  .about-info > p {
    padding: 0px;
  }
}

@media screen and (max-width: 768px) {
  .about-hero {
    margin: 0;
    height: auto;
  }
  .about-hero-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .about-hero-text {
    padding-top: 50px;
    width: 50%;
  }

  .about-hero-text {
    width: 100%;
  }
  .about-hero-subtext {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
  }

  .about-download-icons div > div:first-child {
    border: 1.5px solid white;
    font-size: 25px;
  }

  .about-download-icons div > div:last-child {
    border: 1.5px solid white;
    font-size: 20px;
  }

  .about-info {
    width: 90%;
    padding: 100px 0;
  }
  .about-info > h1 {
    font-size: 24px;
  }

  .about-info-container {
    padding-top: 60px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* gap: 10px;     */
    width: 100%;
  }

  .about-info-container-inner > h3 {
    font-size: 20px;
    line-height: 140%;
    padding-top: 24px;
    letter-spacing: -0.3px;
  }

  .about-info-container-inner > p {
    font-size: 14px;
    line-height: 21px;
    padding-top: 7px;
    margin-bottom: 40px;
  }
  .about-minds-container {
    width: 90%;
    margin: 0 auto;
  }

  .about-minds-container > h1 {
    text-align: left;
    font-size: 24px;
  }

  .about-minds-container > p {
    color: #9e9e9e;
    text-align: left;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    padding: 24px 0;
    padding-bottom: 36px;
  }

  .about-minds-img-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    min-width: 100%;
  }

  .about-minds-img-container > div {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 90px;
  }

  .image__img {
    margin-bottom: 20px;
  }

  .image__title {
    font-size: 16px;
  }

  .image__description {
    font-size: 14px;
  }
}

@media screen and (max-width: 620px) {
  .about-minds-img-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 550px) {
  .about-hero-container {
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .about-hero {
    padding-bottom: 0;
  }

  .about-hero-main-text {
    font-size: 30px;
    line-height: 43px;
  }
  .about-hero-subtext {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #9e9e9e;
  }
  .about-download-icons {
    font-size: 16px;
  }
  .about-download-icons div > div {
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }

  .about-hero-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
  }

  .about-hero-img {
    margin: 0 auto;
    width: 471px;
  }
  .about-info {
    width: 85%;
  }
  .about-info > h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .about-info > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    padding: 0px;
    letter-spacing: -0.3px;
    color: #757575;
  }
  .about-info-container-inner > h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #121212;
    padding-top: 24px;
  }

  .about-info-container-inner > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #757575;
    padding-top: 7px;
  }

  .about-info-container {
    padding-top: 40px;
  }
  .about-minds-container {
    width: 90%;
    margin: auto;
  }
  .about-minds-container > h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #121212;
  }

  .about-minds-container > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #757575;
    padding: 24px 0;
    padding-bottom: 36px;
  }
}

@media screen and (max-width: 380px) {
  .about-hero {
    padding-bottom: 0;
  }

  .about-hero-main-text {
    font-size: 20px;
    line-height: 35px;
  }
  .about-hero-subtext {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #9e9e9e;
  }
  .about-download-icons {
    font-size: 16px;
  }
  .about-download-icons div > div {
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }

  .about-hero-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    height: auto;
  }

  .about-hero-image img {
    margin: 0 auto;
    width: 100%;
  }
  .about-info > h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .about-info > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    padding: 0px;
    letter-spacing: -0.3px;
    color: #757575;
  }

  .about-info-container-inner > h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #121212;
    padding-top: 24px;
  }

  .about-info-container-inner > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #757575;
    padding-top: 7px;
  }

  .about-info-container {
    padding-top: 40px;
  }
  .about-minds-container > h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #121212;
  }

  .about-minds-container > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #757575;
    padding: 24px 0;
    padding-bottom: 36px;
  }

  .about-minds-img-container > div {
    width: 150px;
    height: 150px;
  }
  .image__title {
    top: 104px;
    font-size: 15px;
  }

  .image__description {
    top: 120px;
    font-size: 13px;
  }
}

@media screen and (max-width: 350px) {
  .about-minds-img-container > div {
    width: 135px;
    height: 135px;
  }

  .image__title {
    top: 85px;
    font-size: 15px;
  }

  .image__description {
    top: 102px;
    font-size: 13px;
  }
}
