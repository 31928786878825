* {
  margin: 0;
}

:root {
  --anim-end: -2600px;
  /* 1400 */
}
:root {
  --anim-end-mobile: -6400px;
  /* 1400 */
}

.Testimonials {
  background-color: var(--light-grey-bg);
  height: 700px;
  margin-top: -150px;
  overflow-y: hidden;
  position: relative;
}
.Testimonials-container {
  width: 1250px;
  margin: auto;
  position: relative;
}
.Testimonials-header h3 {
  color: var(--primary-color);
  margin: auto;
  transform: translateY(-250px);
  text-align: center;
  width: 100vw;
  font-size: 36px;
  font-weight: var(--semi-bold);
  width: 1250px;
  line-height: 54px;
}

.Testimonial::before {
  content: "";
  z-index: 99;
  position: absolute;
  width: 100%;
  /* height:710px; */
  height: 100px;
  margin-top: -5px;

  background: -moz-linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#F8F8F8', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.overlay-top {
  width: 100%;
  height: 100px;
  z-index: 100;
  position: absolute;
  top: 0px;
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#F8F8F8', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}
.overlay-bottom {
  width: 100%;
  height: 100px;
  z-index: 100;
  position: absolute;
  bottom: 0px;
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    0deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    0deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    0deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F8F8F8', endColorstr='#FFFFFF', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    0deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    0deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.Testimonial-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  overflow-x: hidden;
}

.TestimonialsLeft,
.TestimonialsMiddle,
.TestimonialsRight,
.TestimonialsMobile {
  animation-name: testimonials;
  animation-duration: 55s;
  animation-iteration-count: infinite;
  animation-direction: linear;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  margin: 0 5px;
}

.TestimonialsMobile{
  display:none;
}

.Testimonial-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 20px;
  padding: 0px;

  margin: 40px 0px;
  color: #000;
}



.Testimonial-text {
  margin-top: 20px;
}
.Testimonial-user-info {
  margin-top: -10px;
}

/* ANIMATION KEYFRAMES */

@keyframes testimonials {
  to {
    transform: translateY(var(--anim-end));
  }
  from {
    transform: translateY(0px);
  }
}

/* RESPONSIVENESS */

@media screen and (max-width: 1250px) {
  .Testimonials-container {
    width: 1000px;
    margin: auto;
  }
  .Testimonials-header h3 {
    width: 1000px;
  }
}

@media screen and (max-width: 1050px) {
  .Testimonials-container {
    width: 90%;
    margin: auto;
  }
  .Testimonials-header h3 {
    width: 90%;
  }
  .Testimonial-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .Testimonial-div {
    margin: 30px 0px;

  }
  .TestimonialsRight{
    display:none;
  }
}

/* MOBILE RESPONSIVENESS */

@media screen and (max-width: 650px) {
  .Testimonial-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .Testimonial-div {
    margin: 20px 0px;

  }
}
@media screen and (max-width: 550px) {
  .TestimonialsLeft,
  .TestimonialsMiddle,
  .TestimonialsRight {
    animation-duration: 20s;
  }

  .TestimonialsMiddle {
    display: none;
  }
  .TestimonialsRight {
    display: none;
  }
  .TestimonialsLeft{
    display:none;
  }
  .TestimonialsMobile{
    display:block;
    animation-duration: 150s;
  }

  .Testimonials-container {
    flex-direction: column;
    width: 90%;
    height: auto;
  }
  .Testimonial-header {
    margin: auto;
  }
  .Testimonials-header h3 {
    width: 90%;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.3px;
    font-weight: var(--semi-bold);
    transform: translateY(-200px);
  }
  .Testimonial-user-name,
  .Testimonial-user-handle,
  .Testimonial-text {
    color: #000;
  }

  @keyframes testimonials {
    to {
      transform: translateY(var(--anim-end-mobile));
    }
    from {
      transform: translateY(0px);
    }
  }
}

@media screen and (max-width: 350px) {
  .Testimonial-user-details {
    flex-wrap: wrap;
  }
  .Testimonial-div {
    width: 280px;
  }
}
@media screen and (max-width: 310px) {
  .Testimonial-div {
    width: 240px;
  }
}
