.Integrated {
  background-color: #fdfdfd;
}

.Integrated-container {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Integrated-text {
  font-size: 30px;
  padding-top: 100px;
  color: var(--primary-color);
  font-weight: var(--semi-bold);
}

.Integrated-institutions {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Integrated-institutions img {
  margin: 0px 50px;
  transition: ease all 0.2s;
  transform: scale(1.1);
}

.Integrated-institutions img:hover {
  transform: scale(1.3);
}

.Integrated-institutions p {
  color: var(--regular-text);
  font-weight: var(--medium);
  font-size: 24px;
  text-align: left;
  margin: 0px 50px;
}
.mobile-institutions {
  display: none;
}
.mobile-institutions img {
  width: 40px;
  height: 40px;
}

/* RESPONSIVENESS */

@media screen and (max-width: 1450px) {
  .Integrated-container {
    width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 1250px) {
}

@media screen and (max-width: 1050px) {
  .Integrated-institutions img {
    margin: 0px 30px;
  }
  .Integrated-institutions p {
    margin: 0px 30px;
  }

  .Integrated-text {
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.3px;
    padding-top: 84px;
  }

  /* .zenith,
    .sterling,
    .standard,
    .piggyvest,
    .cowrywise{
        height:50px;
    } */
  /* .zenith,
    .sterling,
    .standard,
    .piggyvest{
        height:80%;
    }
     */
}

@media screen and (max-width: 900px) {
  .Integrated-institutions img {
    margin: 0px 20px;
    transition: ease all 0.2s;
    transform: scale(0.9);
  }

  .Integrated-institutions img:hover {
    transform: scale(1.1);
  }

  .Integrated-institutions p {
    margin: 0px 20px;
    font-size: 20px;
    width: 70px;
  }
}
@media screen and (max-width: 700px) {
  .Integrated-institutions img {
    margin: 0px 10px;
  }
}

@media screen and (max-width: 750px) {
  .Integrated-institutions {
    display: none;
  }
  .mobile-institutions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0px;
    width: 100vw;
    color:var(--regular-text);
    font-weight:500;
  }
}

/* MOBILE RESPONSIVENESS */
@media screen and (max-width: 550px) {
  .Integrated-container {
    flex-direction: column;
    width: 90%;
    height: auto;
  }
  .Integrated-institutions {
    display: none;
  }

  .hbddWv img {
    transform: scale(0.7);
    transition: ease all;
  }

  .hbddWv img:hover {
    transform: scale(0.9);
  }



  .Integrated-text {
    font-size: 18px;
    width:90%;

  }

  p {
    color: var(--regular-text);
    font-weight: var(--medium);
    font-size: 16px;
    text-align: left;
  }
  .cowrywise img,
  .piggyvest img {
    width: 70px;
    height: 40px;
  }
}

@media screen and (max-width: 380px) {
  .Integrated-text {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
/* TESTING MARQUEE */
