body {
  overflow-x: hidden;
}



* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.App {
  text-align: left;
  font-family: var(--font-family);
  font-weight: 600;
  overflow-x: hidden;
}

.OverlayApp:before {
  content: "";
  position: fixed;
  top: 118px;
  right: 0;
  bottom: 0px;
  left: 0;
  z-index: 100;
  height: 100%;
  display: block;
  background: rgba(18, 18, 18, 0.25);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #56bd82;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
