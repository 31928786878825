.Ambassadors {
  overflow-x: hidden;
}

.Ambassadors-container {
  width: 1250px;
  margin: auto;
  margin-bottom: 300px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.Ambassadors-main-text {
  font-size: 36px;
  font-weight: var(--semi-bold);
  text-align: left;
  margin-top: 70px;
  width: 350px;
}

.Ambassadors-subText {
  text-align: left;
  width: 600px;
  margin-top: 50px;
  color: var(--darker-grey);
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.3px;
}
.Ambassadors-partnerships {
  color: var(--secondary-color);
  margin-top: 40px;
  text-decoration: underline;
}
.Ambassadors-partnerships:hover {
  cursor: pointer;
}

.Ambassador-images {
  display: grid;
  width: 700px;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "image1 image1 image2 image2 image3 image3 image3"
    "image1 image1 image2 image2 image3 image3 image3"
    "image4 image4 image4 image4 image3 image3 image3"
    "image4 image4 image4 image4 image3 image3 image3";
  gap: 0px;
  margin-top: 50px;
  position: relative;
  transform: translateX(100px);
}

.Ambassador-1 {
  grid-area: image1;
  background-color: #c4c4c4;
  width: 206px;
  height: 240px;
  border-radius: 10px;
}
.Ambassador-2 {
  grid-area: image2;
  background-color: #c4c4c4;
  width: 206px;
  height: 240px;
  border-radius: 10px;
}
.Ambassador-3 {
  grid-area: image3;
  background-color: #c4c4c4;
  width: 274px;
  height: 508px;
  border-radius: 10px;
}
.Ambassador-4 {
  grid-area: image4;
  background-color: #c4c4c4;
  width: 425px;
  height: 253.6px;
  margin-right: 10px;
  border-radius: 10px;
}

.Ambassador-images-mobile {
  display: none;
}
.Ambassadors-meet {
  display: none;
}


.Ambassador-1-mobile img,
.Ambassador-2-mobile img,
.Ambassador-3-mobile img,
.Ambassador-4-mobile img
{
  border-radius:10px;
}


@media screen and (max-width: 1450px) {
  .Ambassadors-container {
    width: 90%;
    margin: auto;
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 1250px) {
  .Ambassadors-container {
    flex-direction: column;
  }
  .Ambassadors-mobile-flex {
    width: 100%;
    display: flex;
    height: 600px;
  }
  .Ambassador-images {
    transform: translateX(0px);
  }

  .Ambassadors-main-text {
    width: 100%;
    margin: 0px auto;
  }
  .Ambassadors-subText {
    width: 100%;

    margin: 30px auto;
  }

  .Ambassador-images-bigger {
    display: none;
  }
  .bigger {
    display: none;
  }
  .Ambassador-images-mobile {
    display: block;
    width: 96%;

    padding-bottom: 150px;
  }
  .Ambassadors-mobile-flex {
    width: 100%;
    display: flex;
    height: 800px;
  }
  .left-mobile-images {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .right-mobile-images {
    width: 40%;
  }
  .Ambassador-1-mobile {
    grid-area: image1;
    background-color: #c4c4c4;
    width: 48%;
    height: 95%;
    margin-bottom: 5%;
    margin-right: 2%;
    border-radius: 10px;
  }
  .Ambassador-2-mobile {
    grid-area: image2;
    background-color: #c4c4c4;
    width: 48%;
    height: 95%;
    margin-left: 2%;
    margin-bottom: 5%;
    border-radius: 10px;
  }
  .Ambassador-3-mobile {
    grid-area: image3;
    background-color: #c4c4c4;
    width: 106%;
    height: 100%;
    border-radius: 10px;
    margin-left: 5%;
  }
  .Ambassador-4-mobile {
    grid-area: image4;
    background-color: #c4c4c4;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .left-top-images {
    display: flex;
    justify-content: flex-start;
    height: 50%;
  }
  .left-bottom-images {
    height: 50%;
  }
  .right-mobile-images {
    height: 100%;
  }
  .Ambassadors-partnerships {
    text-align: center;
    margin: 40px auto;
  }
}

@media screen and (max-width: 1050px) {
  .Ambassadors-main-text {
    width: auto;
    margin: auto;
  }
  .Ambassadors-mobile-flex {
    height: 600px;
  }
}

@media screen and (max-width: 850px) {
  .Ambassadors-mobile-flex {
    height: 500px;
  }
}

@media screen and (max-width:650px){
  .Ambassadors-mobile-flex {
    height: 400px;
  }
}

@media screen and (max-width: 550px) {
  .Ambassadors-container {
    margin-left: 13px;
    margin-right: 13px;
    flex-direction: column;
    padding-bottom: 250px;
    margin-bottom: 0;
  }
  .Ambassador-images {
    display: block;
  }
  .Ambassadors-main-text {
    width: auto;
    font-size: 20px;
    margin-bottom: 50px;
    text-align: left;
    margin-left: 20px;
  }
  .Ambassadors-subText {
    font-size: 14px;
    width: 90%;
    text-align: left;
    line-height: 21px;
    margin-bottom:50px;
  }

  .Ambassadors-meet {
    display: block;
    text-align: center;
    margin: 20px 0;
    font-size: 20px;
    font-weight: var(--semi-bold);
  }
  .Ambassador-images-bigger {
    display: none;
  }
  .bigger {
    display: none;
  }
  .Ambassador-images-mobile {
    display: block;
    width: 100%;
    padding-bottom: 250px;
  }
  .Ambassadors-mobile-flex {
    height: 270px;
  }
  .Ambassador-images-mobile {
    display: block;
    width: 100%;
    padding-bottom: 25px;
  }
  .Ambassadors-meet {
    margin: 50px auto;
  }
}

@media screen and (max-width: 390px) {
  .Ambassadors-mobile-flex {
    height: 245px;
  }
}

@media screen and (max-width: 360px) {
  .Ambassadors-subText {
    font-size: 12px;
    line-height: 25px;
  }
}

@media screen and (max-width: 320px) {
  .Ambassadors-mobile-flex {
    height: 200px;
  }
}
