.Navbar {
  background-color: var(--primary-color);
  color: var(--button-text);
  margin-bottom: -1px;
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 200;
}

.shadowNav {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.418);
}

.mantine-vao037 {
  display: none;
}

.Navbar a.active {
  font-weight: var(--medium);
  color: var(--secondary-color);
}
.nav-download-apple-logo {
  margin-right: 30px !important;
}

.nav-download-icons {
  cursor: pointer;
}

.nav-download-icons div > div:first-child {
  border: 1.5px solid white;
  font-size: 25px;
}

.nav-download-icons div > div:last-child {
  border: 1.5px solid white;
  font-size: 20px;
}
.nav-download-icons div > div:hover {
  color: black;
}
.nav-download-icons div > div:hover {
  background-color: white;
}

.nav-download {
  font-size: 20px;
  color: var(--button-text);
  text-align: left;
  margin-top: 50px;
  font-weight: var(--medium);
}

.nav-download {
  display: flex;
  align-items: flex-start;
}
.nav-download-text {
  margin-top: -8px;
}

.nav-download-icons {
  display: flex;
  text-align: left;
  font-size: 20px;
}
.nav-download-icons div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.nav-download-icons div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 0;
  transition: all 200ms ease;
}

.nav-download-icons div > div:first-child {
  border: 1.5px solid white;
  font-size: 25px;
}

.nav-download-icons div > div:last-child {
  border: 1.5px solid white;
  font-size: 20px;
}

.nav-download-flex {
  justify-content: flex-start;
  align-items: flex-start;
}

.trakka__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  background-color: var(--primary-color);
  margin-bottom: -1px;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 1250px;
  margin: auto;
  height: 98px;
}

.trakka__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.trakka__navbar-links_logo {
  font-family: var(--font-family);
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 38px;
  /* identical to box height */
  /* z-index: 1000; */
  letter-spacing: -0.06em;
}

.trakka__navbar-links_container {
  display: flex;
  flex-direction: row;
}

.trakka__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: ease all;
}

.help {
  margin-left: 50px;
}

.download-icons-item {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 48px;
  border-radius: 100px;
  border: 1px solid white;
}
.about,
.help {
  font-size: 20px;
  font-family: var(--font-family);
  font-weight: var(--medium);
  transition: all 200ms ease;
}

.about:hover,
.help:hover {
  color: white;
  transform: scale(1.05);
  transition: all 200ms ease;
}

.trakka__navbar-menu {
  display: none;
  /* position: relative; */
}

.trakka__navbar-menu svg {
  cursor: pointer;
  z-index: 120;
}

.trakka__navbar-menu_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  z-index: 100;
  background: var(--primary-color);

  position: absolute;
  right: -4vw;
  margin-top: 0;
  min-width: 210px;
  border-radius: 1px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  width: 90%;
  border-radius: 20px;
  height: auto;
  margin-top: -15px;
  padding: 17px 18px;
}

.download-icons {
  display: none;
  visibility: hidden;
  transition: all 200ms ease;
}

.download-icons > div {
  transition: all 200ms ease;
}

.download-icons div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-icons div > a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  cursor: pointer;
  transition: all 200ms ease;
  text-decoration: none;
  color: white;
}

.download-icons div > a:first-child {
  font-size: 30px;
  border: 1.5px solid white;
  margin-left: 50px;
}

.download-icons div > a:last-child {
  border: 1.5px solid white;
  font-size: 25px;
  margin-left: 50px;
}

.download-icons div a:hover {
  color: black;
}

.download-icons div > a:hover {
  background-color: white;
}

.nav-download-icons > div {
  transition: all 200ms ease;
}

.nav-download-icons div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-download-icons div > a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  cursor: pointer;
  transition: all 200ms ease;
  text-decoration: none;
  color: white;
}

.nav-download-icons div > a:first-child {
  font-size: 10px;
  border: 1.5px solid white;
  margin-left: 0px;
}

.nav-download-icons div > a:first-child svg {
  font-size: 5px;
}

.nav-download-icons div > a:last-child {
  border: 1.5px solid white;
  font-size: 25px;
  margin-left: 25px;
}

.nav-download-icons div a:hover {
  color: black;
}

.nav-download-icons div > a:hover {
  background-color: white;
}

.slide {
  display: flex;
  visibility: visible;
  animation: slide ease 1s;
}

.slideItem {
  animation: slideItem ease 1s;
}

.revslideItem {
  animation: revslideItem ease 1s;
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideItem {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes revslideItem {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.mobile-logo {
  display: none;
}

@media screen and (max-width: 1450px) {
  .trakka__navbar {
    width: 90%;
  }
  .mantine-1n1p8xp {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .trakka__navbar-menu_container {
    background-color: var(--primary-color);
    padding: 0px 20px;
  }

  .Navbar svg {
    font-size: 26px;
    width: 60px;
    margin-top: 2px;
  }

  .ham-about {
    padding-top: 40px;
  }

  .slide {
    display: none;
  }

  .ham-help {
    padding-top: 40px;
  }

  .ham-download {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .trakka__navbar {
    width: 100%;
    padding: 0px 32px;
    height: 80px;
  }

  .trakka__navbar-sign {
    display: none;
  }

  .trakka__navbar-links_container {
    display: none;
  }

  .trakka__navbar-menu {
    display: flex;
  }
  .ham-about,
  .ham-help {
    font-size: 16px;
    font-weight: var(--medium);
    width: 100px;
  }

  .mantine-1n1p8xp {
    display: block;
    border-radius: 10px;
    background-color: var(--primary-color);
    color: var(--button-text);
    padding: 18px 0px;
    padding-top: 0px;
    padding-bottom: 25px;
    width: 110%;
    height: auto;
    font-family: var(--font-family);
    margin-top: -10px;
  }

  .menu-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    top: 0;
    right: 0;
    margin: 0 auto;
    /* display: none; */
    background-color: rgba(0, 0, 0, 0.2);
  }
  .ham-container {
    width: 90%;
    margin: auto;
    border-radius: 10px;
    padding: 20px 0;
    height: 320px;
  }
  .ham-header {
    display: flex;
    justify-content: space-between;
    /* padding-right: 20px; */
  }

  .close-btn {
    font-size: 22px;
  }
  .ham-header svg {
    margin-top: -10px;
  }
  .ham-links {
    display: flex;
    flex-direction: column;
    width: auto;
    margin: auto;
  }
  .ham-links a.active {
    color: var(--secondary-color);
  }
  .ham-links a:hover {
    color: var(--secondary-color);
  }
  .ham-logo {
    width: 10%;
    background-repeat: no-repeat;
  }
  .ham-logo img {
    border: 0;
    border: none;
  }
  .ham-logo p {
    opacity: 1;
    color: rgba(0, 0, 0, 0);
  }
}

@media screen and (max-width: 380px) {
  .ham-header {
    padding-right: 22px;
  }

  .Navbar svg {
    font-size: 20px;
  }
  .close-btn {
    font-size: 20px;
  }
  .ham-about,
  .ham-help {
    font-size: 14px;
  }
  .mobile-logo {
    display: block;
  }
  .mobile-logo img {
    width: 91px;
  }
  .ham-header img {
    width: 91px;
  }

  .default-logo {
    display: none;
  }
}
