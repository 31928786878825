.Track {
  background: #fff;
}

.Track-container {
  width: 1250px;
  margin: auto;
}

.Track-header {
  font-size: 36px;
  font-weight: var(--semi-bold);
  padding-top: 140px;
  width: 890px;
  margin: 0 auto;
  text-align: center;
}
.Track-text {
  font-size: 24px;
  color: #757575;
  font-weight: var(--regular);
  margin: auto;
  text-align: center;
  margin: 20px 0;
}

.Track-feature-container {
  margin: 0px;
  margin: 80px auto;
  margin-bottom: 140px;
  width: 1250px;
  height: 600px;
  background-color: var(--primary-color);
  border-radius: 25px;
  padding: 50px;
  display: flex;
  justify-content: space-between;
  color: var(--button-text);
  position: relative;
}

/* IPHONE IMAGE */

.Track-display {
  width: 350px;
  height: 628px;
}

.Track-display img {
  position: absolute;
  bottom: 0;
  right: 70px;
  width: 350px;
}

/* TABS */

.buttons {
  width: 542px;
  display: flex;
  justify-content: space-between;
}

/* DEFAULT */

.button {
  font-size: 24px;
  font-weight: var(--medium);
  /* line-height:36px; */

  padding: 20px 40px;
  border-radius: 100px;

  color: var(--regular-text);
  transition: all 0.2s ease-in;
}

.button:hover {
  cursor: pointer;
  color: var(--secondary-color);
  transition: all 0.2s ease-in;
}

#expenses {
  margin: 0px 10px;
}

/* selected BUTTON */

.selected {
  font-size: 24px;
  font-weight: var(--medium);
  /* line-height:36px; */
  padding: 20px 40px;
  height: 76px;
  border-radius: 100px;
  transition: all 0.2s ease-in;
  background-color: var(--secondary-color);
  color: var(--button-text);
}
.selected:hover {
  cursor: pointer;
}

/* CONTENT */

.Tabs-content {
  margin-top: 75px;
  text-align: left;
}

.Tabs-main-text {
  font-size: 36px;
  width: 452px;
  line-height: 54px;
}

.Tabs-subtext {
  width: 469px;
  margin-top: 40px;
  font-weight: var(--regular);
  color: var(--regular-text);
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.3px;
}

/* RESPONSIVENESS */
@media screen and (max-width: 1450px) {
  .Track-container {
    width: 90%;
    margin: auto;
  }
  .Track-feature-container {
    width: auto;
  }
}

@media screen and (max-width: 1250px) {
}

@media screen and (max-width: 1050px) {
  .Track-display img {
    right: 50%;
    transform: translateX(50%);
  }
  .Track-header,
  .Track-text {
    width: 100%;
  }

  .buttons {
    width: 100%;
    margin: auto;
    align-items: center;
    overflow-x: visible;
  }
  .button {
    width: 30%;
    text-align: center;
  }
  .selected {
    width: 30%;
    text-align: center;
    border-radius: 50px;
  }
  .Tabs-main-text,
  .Tabs-subtext {
    width: 100%;
  }
  .Tabs-container {
    height: 300px;
  }
  .Track-display {
    height: auto;
    padding-top: 600px;
  }
  .Track-feature-container {
    width: auto;
    flex-direction: column;
  }
}

@media screen and (max-width: 820px){
  .Track-text{
    font-size:18px;
  }
}



@media screen and (max-width: 800px) {
  .Track-feature-container {
    padding: 50px;
  }
  .button {
    font-size: 20px;
    line-height: 24px;
    padding: 20px 20px;
    text-align: center;
    letter-spacing: -0.3px;
  }
  .selected {
    font-size: 20px;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
  }
  .buttons {
    width: 100%;
  }
  .button {
    display: grid;
    align-items: center;
    justify-content: center;
  }
  .Tabs-subtext {
    width: auto;
  }
  #expenses {
    margin: 0px 0px;
  }
  .Tabs-main-text {
    width: auto;
  }
  .Track-display {
    height: auto;
    padding-top: 650px;
  }
}

/* MOBILE RESPONSIVENESS */

@media screen and (max-width: 550px) {
  .Track-container {
    flex-direction: column;
    width: 100%;
    padding-left: 13px;
    padding-right: 13px;
    height: auto;
  }

  .Track-feature-container {
    flex-direction: column;
    margin-top: 50px;
    padding: 30px 20px;
  }
  .Track-header {
    width: 100%;
    padding-left: 52px;
    padding-right: 52px;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.3px;
    padding-top:96px;
  }
  .button {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 10px;
    text-align: center;
    letter-spacing: -0.3px;
  }
  .selected {
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.3px;
    font-weight: var(--medium);
    /* line-height:36px; */
    padding: 10px 10px;
    height: auto;
    border-radius: 50px;
    transition: all 0.2s ease-in;
  }
  .buttons {
    width: 100%;
  }
  .Tabs-content {
    margin-top: 50px;
  }
  .Tabs-main-text {
    width: auto;
    line-height: 54px;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.3px;
  }
  .Tabs-subtext {
    width: auto;
    margin-top: 30px;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.3px;
  }
  .Track-display {
    width: 300px;
    height: 300px;
  }
  .Track-display img {
    width: 300px;
    height: 300px;
  }
  .Track-text {
    font-size: 14px;
    line-height: 21px;
    font-weight: var(--regular);
  }
  .Track-display {
    height: auto;
    padding-top: 350px;
  }
}

@media screen and (max-width: 380px) {
  .Tabs-subtext {
    font-size: 14px;
  }

  .button {
    font-size: 13px;
  }
  .selected {
    font-size: 13px;
  }

  .Track-header {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  .Track-text {
    width: 100%;
    font-size: 13px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 340px) {
  .button {
    font-size: 12px;
  }

  .Track-header {
    width: 100%;
    font-size: 18;
    padding-left: 0px;
    padding-right: 0px;
  }

  .Track-text {
    width: 100%;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .selected {
    font-size: 12px;
  }
  .Track-display {
    height: auto;
    padding-top: 380px;
  }
}
