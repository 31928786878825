.cta-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 81px 0 107px;
  width: 1250px;
  height: 360px;
  background-color: #F3FAF6;
  background-image: url(../../assets/Rectangle.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 40px;
  overflow: hidden;
  position: relative;
}

.cta-container-text>h1 {
  width: 80%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: -0.3px;
}

.cta-container-img {
  position: relative;
}

/* download icons */
.cta-icons {
  display: flex;
  align-items: center;
  margin-top: 58px;
  text-align: left;
  font-size: 20px;
}

.cta-icons p {
  margin: 0;

}

.cta-icons div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-icons div>a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 15px;
  cursor: pointer;
  transition: all 200ms ease;
  text-decoration: none;
  color: black;
}


.cta-icons div>a:first-child {
  font-size: 30px;
  border: 1.5px solid black;
}

.cta-icons div>a:last-child {
  border: 1.5px solid black;
  font-size: 25px;
}

.cta-icons div a:hover {
  color: white;
}

.cta-icons div>a:hover {
  background-color: black;
}

/* phone img */
.phone-screen {
  position: absolute;
  top: -90px;
  right: 13px;
  width: 238px;
  z-index: 1;
}

.arrow {
  position: absolute;
  right: 400px;
}

.cta {
  padding: 140px 0;
}


/* responsiveness */

@media screen and (max-width: 1250px) {
  .cta {
    width: 90%;
    margin: auto;
    padding: 120px 0;
  }

  .cta-container {
    width: 100%;
    margin: auto;
  }


}

@media screen and (max-width: 1024px) {
  .cta {
    width: 90%;
    margin: auto;
    padding: 120px 0;
  }

  .arrow {
    display: none;
  }
}

@media screen and (max-width: 830px) {
  .cta {
    width: 90%;
    margin: auto;
    padding: 120px 0;
  }

  .cta-container {
    padding: 0 20px 0 40px;
  }

  .cta-icons>p {
    display: none;
  }

  .cta-container {
    /* align-items: flex-start; */
    height: 370px;
    padding: 40px 30px;
    text-align: left;
  }

  .cta-icons div>div {
    margin: 0 15px 0 0;

  }

  .cta-container-text {
    width: 50%;
  }


  .cta-container-img {
    position: static;
    margin: 0 auto;
  }

  .phone-screen {
    width: 230px;
    margin-bottom: -200px;
    position: static;
  }

  .cta-container-text>h1 {
    width: 100%;
    font-size: 45px;
    line-height: 60px;
  }

  .cta-icons {
    margin-top: 15px;

  }


}

@media screen and (max-width: 550px) {
  .phone-screen {
    width: 150px;
    margin-bottom: -40px;
    position: static;
  }

  .cta-container {
    flex-direction: column;
    width: 100%;
    height: 430px;
  }

  .cta-container-text {
    width: 100%;
  }

  .cta-container-text>h1 {
    width: 100%;
    margin-bottom: 26px;
    font-size: 24px;
    line-height: 40px;
  }

  .cta-icons {
    margin-top: 0;

  }

  .phone-screen {
    width: 150px;
    margin-bottom: -50px;
    position: static;
  }

  .cta-icons div>div {
    width: 40px;
    height: 40px;
    margin: 0 9px;
  }

  .arrow {
    width: 40px;
    display: block;
    position: absolute;
    bottom: 140px;
    left: 50px;
  }
}

@media screen and (max-width:375px) {
  .arrow {
    display: none;
  }
}