@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
:root {
  --font-family: "Poppins", sans-serif;
  --primary-color: #121212;
  --secondary-color: #56bd82;
  --regular-text: #9e9e9e;
  --button-text: #fafafa;
  --regular: 400;
  --medium: 500;
  --semi-bold: 600;
  --light-grey-bg: #f8f8f8;
  --light-green: #f3faf6;
  --darker-grey: #757575;
}

a {
  color: #fafafa;
  color: var(--button-text);
  text-decoration: none;
}

.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 0.5s
    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

body {
  overflow-x: hidden;
}



* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.App {
  text-align: left;
  font-family: var(--font-family);
  font-weight: 600;
  overflow-x: hidden;
}

.OverlayApp:before {
  content: "";
  position: fixed;
  top: 118px;
  right: 0;
  bottom: 0px;
  left: 0;
  z-index: 100;
  height: 100%;
  display: block;
  background: rgba(18, 18, 18, 0.25);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #56bd82;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Hero {
  background-color: var(--primary-color);
  color: var(--button-text);
}

.Hero-container {
  width: 1250px;
  margin: auto;
  display: flex;
  height: 1000px;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 150px;
}

.Hero-download-apple-logo {
  margin-left: 0px !important;
}

.Hero-download-icons {
  cursor: pointer;
}



.Hero-download-icons div > div:first-child {
  border: 1.5px solid white;
  font-size: 30px;
}

.Hero-download-icons div > div:last-child {
  border: 1.5px solid white;
  font-size: 25px;
}
.Hero-download-icons div > div:hover {
  color: black;
}
.Hero-download-icons div > div:hover {
  background-color: white;
}

/* HERO LEFT HALF */

.Hero-text {
  width: 50%;
  margin-top: 200px;
  font-style: normal;
}
.Hero-main-text {
  font-size: 60px;
  text-align: left;
  font-style: normal;
}
.Hero-subtext {
  text-align: left;
  font-size: 18px;
  font-weight: var(--regular);
  color: var(--regular-text);
  margin-top: 50px;
  width: 698px;
}

.Hero-download {
  font-size: 20px;
  color: var(--button-text);
  text-align: left;
  margin-top: 50px;
  font-weight: var(--medium);
}

.Hero-download {
  display: flex;
  align-items: center;
}
.Hero-download-text {
  margin: 0px;
  margin-right: 24px;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
}

.Hero-download-icons {
  display: flex;
  text-align: left;
  font-size: 20px;
}
.Hero-download-icons > div {
  transition: all 200ms ease;
}

.Hero-download-icons div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Hero-download-icons div>a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  
  cursor: pointer;
  transition: all 200ms ease;
  text-decoration: none;
  color: white;
}


.Hero-download-icons div>a:first-child {
  font-size: 30px;
  border: 1.5px solid white;
  margin-left:0px;
}

.Hero-download-icons div>a:last-child {
  border: 1.5px solid white;
  font-size: 25px;
  margin-left: 25px;
}

.Hero-download-icons div a:hover {
  color: black;
}

.Hero-download-icons div>a:hover {
  background-color: white;
}
/* .Hero-download-flex{
    justify-content: flex-start;
    align-items: flex-start;
  } */

/* HERO RIGHT HALF (PHONE IMG)*/

.Hero-image {
  position: relative;
  margin-top: 140px;
}
.phoneScreen {
  position: absolute;
  left: 12px;
  top: 12px;
}
.mobile {
  display: none;
}

/* RESPONSIVENESS */

@media screen and (max-width: 1450px) {
  .Hero-container {
    width: 90%;
    margin: auto;
  }
  .Hero-text {
    font-style: normal;
  }
  .Hero-container {
    height: auto;
  }
}

@media screen and (max-width: 1250px) {
}

@media screen and (max-width: 1050px) {
  .Hero-image {
    display: none;
  }
  .Hero-text {
    margin-top: 100px;
    
    font-style: normal;
    width: 100%;
  }
  .Hero-container{
    padding-bottom:100px;
  }
}

@media screen and (max-width: 770px) {
  .Hero-text {
    margin: 0;
    padding-top: 50px;
  }

  .Hero-main-text {
    font-size: 50px;
  }

  .Hero-subtext {
    font-size: 16px;
  }
}

/* MOBILE RESPONSIVENESS */
@media screen and (max-width: 550px) {
  .Hero-container {
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    padding: 0px 32px;
    padding-top: 50px;
    height: auto;
    min-height: 1000px;
    padding-bottom: 75px;
  }

  .Hero-main-text {
    line-height: 48px;
    font-size: 32px;
    padding-right: 20px;
  }
  .Hero-text {
    width: 90%;

    font-style: normal;
  }
  .Hero-subtext {
    font-size: 14px;
    line-height: 21px;
    margin-top: 36px;
    font-weight: 400;
    width: 100%;
  }
  .Hero-download {
    font-size: 16px;
    margin-top: 44px;
  }
  .Hero-download-icons div > div {
    width: 40px;
    height: 40px;
  }

  .Hero-download-text {
    display: none;
  }
  .Hero-download-icons {
    font-size: 16px;
  }
  .Hero-download-icons div > div:first-child {
    border: 1.5px solid white;
    font-size: 25px;
  }

  .Hero-download-icons div > div:last-child {
    border: 1.5px solid white;
    font-size: 20px;
  }
  .Hero-image {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 96px;
  }
  .desktop {
    display: none;
  }
  .mobile img {
    /* transform:translateX(-10px); */
  }
  .Hero-download-icons {
    font-size: 16px;
  }
  .Hero-download-icons div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .Hero-download-icons div > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* margin: 0 15px; */
    margin: 0;
    margin-left: 30px;
    transition: all 200ms ease;
  }

  .Hero-download-icons div > div:first-child {
    border: 1.5px solid white;
    font-size: 25px;
  }

  .Hero-download-icons div > div:last-child {
    border: 1.5px solid white;
    font-size: 20px;
  }
  .Hero-image {
    margin-top: 104px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

@media screen and (max-width: 400px) {
  .Hero-main-text {
    font-size: 30px;
  }
  .Hero-subtext {
    font-size: 13px;
  }

  .Hero-image {
    margin-top: 50px;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 330px) {
  .Hero-main-text {
    font-size: 24px;
    line-height: 34px;
  }
  .Hero-subtext {
    font-size: 12px;
    width: 110%;
  }

  .Hero-image {
    margin-top: 50px;

  }
}

.Navbar {
  background-color: var(--primary-color);
  color: var(--button-text);
  margin-bottom: -1px;
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 200;
}

.shadowNav {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.418);
}

.mantine-vao037 {
  display: none;
}

.Navbar a.active {
  font-weight: var(--medium);
  color: var(--secondary-color);
}
.nav-download-apple-logo {
  margin-right: 30px !important;
}

.nav-download-icons {
  cursor: pointer;
}

.nav-download-icons div > div:first-child {
  border: 1.5px solid white;
  font-size: 25px;
}

.nav-download-icons div > div:last-child {
  border: 1.5px solid white;
  font-size: 20px;
}
.nav-download-icons div > div:hover {
  color: black;
}
.nav-download-icons div > div:hover {
  background-color: white;
}

.nav-download {
  font-size: 20px;
  color: var(--button-text);
  text-align: left;
  margin-top: 50px;
  font-weight: var(--medium);
}

.nav-download {
  display: flex;
  align-items: flex-start;
}
.nav-download-text {
  margin-top: -8px;
}

.nav-download-icons {
  display: flex;
  text-align: left;
  font-size: 20px;
}
.nav-download-icons div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.nav-download-icons div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 0;
  transition: all 200ms ease;
}

.nav-download-icons div > div:first-child {
  border: 1.5px solid white;
  font-size: 25px;
}

.nav-download-icons div > div:last-child {
  border: 1.5px solid white;
  font-size: 20px;
}

.nav-download-flex {
  justify-content: flex-start;
  align-items: flex-start;
}

.trakka__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  background-color: var(--primary-color);
  margin-bottom: -1px;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 1250px;
  margin: auto;
  height: 98px;
}

.trakka__navbar-links {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.trakka__navbar-links_logo {
  font-family: var(--font-family);
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 38px;
  /* identical to box height */
  /* z-index: 1000; */
  letter-spacing: -0.06em;
}

.trakka__navbar-links_container {
  display: flex;
  flex-direction: row;
}

.trakka__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: ease all;
}

.help {
  margin-left: 50px;
}

.download-icons-item {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 48px;
  border-radius: 100px;
  border: 1px solid white;
}
.about,
.help {
  font-size: 20px;
  font-family: var(--font-family);
  font-weight: var(--medium);
  transition: all 200ms ease;
}

.about:hover,
.help:hover {
  color: white;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: all 200ms ease;
}

.trakka__navbar-menu {
  display: none;
  /* position: relative; */
}

.trakka__navbar-menu svg {
  cursor: pointer;
  z-index: 120;
}

.trakka__navbar-menu_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  z-index: 100;
  background: var(--primary-color);

  position: absolute;
  right: -4vw;
  margin-top: 0;
  min-width: 210px;
  border-radius: 1px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  width: 90%;
  border-radius: 20px;
  height: auto;
  margin-top: -15px;
  padding: 17px 18px;
}

.download-icons {
  display: none;
  visibility: hidden;
  transition: all 200ms ease;
}

.download-icons > div {
  transition: all 200ms ease;
}

.download-icons div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-icons div > a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  cursor: pointer;
  transition: all 200ms ease;
  text-decoration: none;
  color: white;
}

.download-icons div > a:first-child {
  font-size: 30px;
  border: 1.5px solid white;
  margin-left: 50px;
}

.download-icons div > a:last-child {
  border: 1.5px solid white;
  font-size: 25px;
  margin-left: 50px;
}

.download-icons div a:hover {
  color: black;
}

.download-icons div > a:hover {
  background-color: white;
}

.nav-download-icons > div {
  transition: all 200ms ease;
}

.nav-download-icons div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-download-icons div > a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  cursor: pointer;
  transition: all 200ms ease;
  text-decoration: none;
  color: white;
}

.nav-download-icons div > a:first-child {
  font-size: 10px;
  border: 1.5px solid white;
  margin-left: 0px;
}

.nav-download-icons div > a:first-child svg {
  font-size: 5px;
}

.nav-download-icons div > a:last-child {
  border: 1.5px solid white;
  font-size: 25px;
  margin-left: 25px;
}

.nav-download-icons div a:hover {
  color: black;
}

.nav-download-icons div > a:hover {
  background-color: white;
}

.slide {
  display: flex;
  visibility: visible;
  -webkit-animation: slide ease 1s;
          animation: slide ease 1s;
}

.slideItem {
  -webkit-animation: slideItem ease 1s;
          animation: slideItem ease 1s;
}

.revslideItem {
  -webkit-animation: revslideItem ease 1s;
          animation: revslideItem ease 1s;
}

@-webkit-keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideItem {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideItem {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes revslideItem {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes revslideItem {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.mobile-logo {
  display: none;
}

@media screen and (max-width: 1450px) {
  .trakka__navbar {
    width: 90%;
  }
  .mantine-1n1p8xp {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .trakka__navbar-menu_container {
    background-color: var(--primary-color);
    padding: 0px 20px;
  }

  .Navbar svg {
    font-size: 26px;
    width: 60px;
    margin-top: 2px;
  }

  .ham-about {
    padding-top: 40px;
  }

  .slide {
    display: none;
  }

  .ham-help {
    padding-top: 40px;
  }

  .ham-download {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .trakka__navbar {
    width: 100%;
    padding: 0px 32px;
    height: 80px;
  }

  .trakka__navbar-sign {
    display: none;
  }

  .trakka__navbar-links_container {
    display: none;
  }

  .trakka__navbar-menu {
    display: flex;
  }
  .ham-about,
  .ham-help {
    font-size: 16px;
    font-weight: var(--medium);
    width: 100px;
  }

  .mantine-1n1p8xp {
    display: block;
    border-radius: 10px;
    background-color: var(--primary-color);
    color: var(--button-text);
    padding: 18px 0px;
    padding-top: 0px;
    padding-bottom: 25px;
    width: 110%;
    height: auto;
    font-family: var(--font-family);
    margin-top: -10px;
  }

  .menu-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    top: 0;
    right: 0;
    margin: 0 auto;
    /* display: none; */
    background-color: rgba(0, 0, 0, 0.2);
  }
  .ham-container {
    width: 90%;
    margin: auto;
    border-radius: 10px;
    padding: 20px 0;
    height: 320px;
  }
  .ham-header {
    display: flex;
    justify-content: space-between;
    /* padding-right: 20px; */
  }

  .close-btn {
    font-size: 22px;
  }
  .ham-header svg {
    margin-top: -10px;
  }
  .ham-links {
    display: flex;
    flex-direction: column;
    width: auto;
    margin: auto;
  }
  .ham-links a.active {
    color: var(--secondary-color);
  }
  .ham-links a:hover {
    color: var(--secondary-color);
  }
  .ham-logo {
    width: 10%;
    background-repeat: no-repeat;
  }
  .ham-logo img {
    border: 0;
    border: none;
  }
  .ham-logo p {
    opacity: 1;
    color: rgba(0, 0, 0, 0);
  }
}

@media screen and (max-width: 380px) {
  .ham-header {
    padding-right: 22px;
  }

  .Navbar svg {
    font-size: 20px;
  }
  .close-btn {
    font-size: 20px;
  }
  .ham-about,
  .ham-help {
    font-size: 14px;
  }
  .mobile-logo {
    display: block;
  }
  .mobile-logo img {
    width: 91px;
  }
  .ham-header img {
    width: 91px;
  }

  .default-logo {
    display: none;
  }
}

.Integrated {
  background-color: #fdfdfd;
}

.Integrated-container {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Integrated-text {
  font-size: 30px;
  padding-top: 100px;
  color: var(--primary-color);
  font-weight: var(--semi-bold);
}

.Integrated-institutions {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Integrated-institutions img {
  margin: 0px 50px;
  transition: ease all 0.2s;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.Integrated-institutions img:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.Integrated-institutions p {
  color: var(--regular-text);
  font-weight: var(--medium);
  font-size: 24px;
  text-align: left;
  margin: 0px 50px;
}
.mobile-institutions {
  display: none;
}
.mobile-institutions img {
  width: 40px;
  height: 40px;
}

/* RESPONSIVENESS */

@media screen and (max-width: 1450px) {
  .Integrated-container {
    width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 1250px) {
}

@media screen and (max-width: 1050px) {
  .Integrated-institutions img {
    margin: 0px 30px;
  }
  .Integrated-institutions p {
    margin: 0px 30px;
  }

  .Integrated-text {
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.3px;
    padding-top: 84px;
  }

  /* .zenith,
    .sterling,
    .standard,
    .piggyvest,
    .cowrywise{
        height:50px;
    } */
  /* .zenith,
    .sterling,
    .standard,
    .piggyvest{
        height:80%;
    }
     */
}

@media screen and (max-width: 900px) {
  .Integrated-institutions img {
    margin: 0px 20px;
    transition: ease all 0.2s;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }

  .Integrated-institutions img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .Integrated-institutions p {
    margin: 0px 20px;
    font-size: 20px;
    width: 70px;
  }
}
@media screen and (max-width: 700px) {
  .Integrated-institutions img {
    margin: 0px 10px;
  }
}

@media screen and (max-width: 750px) {
  .Integrated-institutions {
    display: none;
  }
  .mobile-institutions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0px;
    width: 100vw;
    color:var(--regular-text);
    font-weight:500;
  }
}

/* MOBILE RESPONSIVENESS */
@media screen and (max-width: 550px) {
  .Integrated-container {
    flex-direction: column;
    width: 90%;
    height: auto;
  }
  .Integrated-institutions {
    display: none;
  }

  .hbddWv img {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    transition: ease all;
  }

  .hbddWv img:hover {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }



  .Integrated-text {
    font-size: 18px;
    width:90%;

  }

  p {
    color: var(--regular-text);
    font-weight: var(--medium);
    font-size: 16px;
    text-align: left;
  }
  .cowrywise img,
  .piggyvest img {
    width: 70px;
    height: 40px;
  }
}

@media screen and (max-width: 380px) {
  .Integrated-text {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
/* TESTING MARQUEE */

.Track {
  background: #fff;
}

.Track-container {
  width: 1250px;
  margin: auto;
}

.Track-header {
  font-size: 36px;
  font-weight: var(--semi-bold);
  padding-top: 140px;
  width: 890px;
  margin: 0 auto;
  text-align: center;
}
.Track-text {
  font-size: 24px;
  color: #757575;
  font-weight: var(--regular);
  margin: auto;
  text-align: center;
  margin: 20px 0;
}

.Track-feature-container {
  margin: 0px;
  margin: 80px auto;
  margin-bottom: 140px;
  width: 1250px;
  height: 600px;
  background-color: var(--primary-color);
  border-radius: 25px;
  padding: 50px;
  display: flex;
  justify-content: space-between;
  color: var(--button-text);
  position: relative;
}

/* IPHONE IMAGE */

.Track-display {
  width: 350px;
  height: 628px;
}

.Track-display img {
  position: absolute;
  bottom: 0;
  right: 70px;
  width: 350px;
}

/* TABS */

.buttons {
  width: 542px;
  display: flex;
  justify-content: space-between;
}

/* DEFAULT */

.button {
  font-size: 24px;
  font-weight: var(--medium);
  /* line-height:36px; */

  padding: 20px 40px;
  border-radius: 100px;

  color: var(--regular-text);
  transition: all 0.2s ease-in;
}

.button:hover {
  cursor: pointer;
  color: var(--secondary-color);
  transition: all 0.2s ease-in;
}

#expenses {
  margin: 0px 10px;
}

/* selected BUTTON */

.selected {
  font-size: 24px;
  font-weight: var(--medium);
  /* line-height:36px; */
  padding: 20px 40px;
  height: 76px;
  border-radius: 100px;
  transition: all 0.2s ease-in;
  background-color: var(--secondary-color);
  color: var(--button-text);
}
.selected:hover {
  cursor: pointer;
}

/* CONTENT */

.Tabs-content {
  margin-top: 75px;
  text-align: left;
}

.Tabs-main-text {
  font-size: 36px;
  width: 452px;
  line-height: 54px;
}

.Tabs-subtext {
  width: 469px;
  margin-top: 40px;
  font-weight: var(--regular);
  color: var(--regular-text);
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.3px;
}

/* RESPONSIVENESS */
@media screen and (max-width: 1450px) {
  .Track-container {
    width: 90%;
    margin: auto;
  }
  .Track-feature-container {
    width: auto;
  }
}

@media screen and (max-width: 1250px) {
}

@media screen and (max-width: 1050px) {
  .Track-display img {
    right: 50%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  .Track-header,
  .Track-text {
    width: 100%;
  }

  .buttons {
    width: 100%;
    margin: auto;
    align-items: center;
    overflow-x: visible;
  }
  .button {
    width: 30%;
    text-align: center;
  }
  .selected {
    width: 30%;
    text-align: center;
    border-radius: 50px;
  }
  .Tabs-main-text,
  .Tabs-subtext {
    width: 100%;
  }
  .Tabs-container {
    height: 300px;
  }
  .Track-display {
    height: auto;
    padding-top: 600px;
  }
  .Track-feature-container {
    width: auto;
    flex-direction: column;
  }
}

@media screen and (max-width: 820px){
  .Track-text{
    font-size:18px;
  }
}



@media screen and (max-width: 800px) {
  .Track-feature-container {
    padding: 50px;
  }
  .button {
    font-size: 20px;
    line-height: 24px;
    padding: 20px 20px;
    text-align: center;
    letter-spacing: -0.3px;
  }
  .selected {
    font-size: 20px;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
  }
  .buttons {
    width: 100%;
  }
  .button {
    display: grid;
    align-items: center;
    justify-content: center;
  }
  .Tabs-subtext {
    width: auto;
  }
  #expenses {
    margin: 0px 0px;
  }
  .Tabs-main-text {
    width: auto;
  }
  .Track-display {
    height: auto;
    padding-top: 650px;
  }
}

/* MOBILE RESPONSIVENESS */

@media screen and (max-width: 550px) {
  .Track-container {
    flex-direction: column;
    width: 100%;
    padding-left: 13px;
    padding-right: 13px;
    height: auto;
  }

  .Track-feature-container {
    flex-direction: column;
    margin-top: 50px;
    padding: 30px 20px;
  }
  .Track-header {
    width: 100%;
    padding-left: 52px;
    padding-right: 52px;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.3px;
    padding-top:96px;
  }
  .button {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 10px;
    text-align: center;
    letter-spacing: -0.3px;
  }
  .selected {
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.3px;
    font-weight: var(--medium);
    /* line-height:36px; */
    padding: 10px 10px;
    height: auto;
    border-radius: 50px;
    transition: all 0.2s ease-in;
  }
  .buttons {
    width: 100%;
  }
  .Tabs-content {
    margin-top: 50px;
  }
  .Tabs-main-text {
    width: auto;
    line-height: 54px;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.3px;
  }
  .Tabs-subtext {
    width: auto;
    margin-top: 30px;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.3px;
  }
  .Track-display {
    width: 300px;
    height: 300px;
  }
  .Track-display img {
    width: 300px;
    height: 300px;
  }
  .Track-text {
    font-size: 14px;
    line-height: 21px;
    font-weight: var(--regular);
  }
  .Track-display {
    height: auto;
    padding-top: 350px;
  }
}

@media screen and (max-width: 380px) {
  .Tabs-subtext {
    font-size: 14px;
  }

  .button {
    font-size: 13px;
  }
  .selected {
    font-size: 13px;
  }

  .Track-header {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  .Track-text {
    width: 100%;
    font-size: 13px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 340px) {
  .button {
    font-size: 12px;
  }

  .Track-header {
    width: 100%;
    font-size: 18;
    padding-left: 0px;
    padding-right: 0px;
  }

  .Track-text {
    width: 100%;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .selected {
    font-size: 12px;
  }
  .Track-display {
    height: auto;
    padding-top: 380px;
  }
}

.Pricing {
  z-index: 10;
  position: relative;
  background: #fff;
}

.Pricing-container {
  width: 1250px;
  margin: auto;
}

.Pricing-header {
  color: var(--primary-color);
  text-align: center;
  margin: 0 auto;
  font-size: 36px;
  font-weight: var(--semi-bold);
  padding-bottom: 80px;
}

.Pricing-information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 80px;
}

.Pricing-price,
.Pricing-try,
.Pricing-clickable {
  width: 49%;
  height: 540px;
  border-radius: 30px;
  padding: 50px;
}

.Pricing-price-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Pricing-clickable:hover {
  cursor: pointer;
}

/* LEFT SIDE */

.Pricing-price {
  background-color: var(--secondary-color);
  color: var(--button-text);
  font-weight: var(--medium);
  position: relative;
}
.Pricing-clickable {
  background-color: var(--secondary-color);
  color: var(--button-text);
  font-weight: var(--medium);
  position: relative;
}

.Pricing-price p {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.3px;
  text-align: left;
}
.Pricing-clickable p {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.3px;
  text-align: left;
}
.Pricing-price .small {
  font-size: 20px;
}
.Pricing-clickable .small {
  font-size: 20px;
}

.Pricing .Amount {
  font-size: 48px;
  line-height: 72px;
  margin-top: 40px;
  font-weight: var(--semi-bold);
  text-align: center;
}

.Pricing-price-content {
  width: 462px;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;
  font-size: 18px;
  line-height: 27px;
  font-weight: var(--medium);
}

.Pricing-price-why {
  margin-top: 60px;
  font-size: 24px;
  line-height: 36px;
  -webkit-text-decoration: 1px underline;
          text-decoration: 1px underline;
  text-align: center;
}
.Pricing-price-why:hover {
  cursor: pointer;
}

/* RIGHT-SIDE */

.Pricing-try {
  background-color: var(--light-green);
  position: relative;
}

.Pricing-try p {
  color: var(--primry-color);
  text-align: left;
  font-size: 24px;
  line-height: 36px;
  font-weight: var(--medium);
  letter-spacing: -0.3px;
}

.Pricing-try-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.3px;

  /* regular text */

  color: #9e9e9e;
}

.Pricing-display {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.Pricing-Price-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.3px;
  margin: 0px 25.25px;

  color: #ffffff;
}

.x-size {
  font-size: 24px;
}

/* WHY ISNT TRAKKA FREE ANIM */

.move-up {
  margin-top: 0px;
  line-height: 36px;
  -webkit-text-decoration: 1px underline;
          text-decoration: 1px underline;
  text-align: center;
  -webkit-transform: translateY(-210px);
          transform: translateY(-210px);
  transition: 500ms ease;
  cursor: default !important;
}
.move-down {
  margin-top: 0px;
  line-height: 36px;
  -webkit-text-decoration: 1px underline;
          text-decoration: 1px underline;
  text-align: center;
  -webkit-transform: translateY(60px);
          transform: translateY(60px);
  transition: 500ms ease;
}

.fade-out {
  color: var(--secondary-color);
  transition: 0ms ease;
}
.fade-in {
  color: #fff;
  transition: 0ms ease;
}
.Amount {
  padding-bottom: 40px;
}

.why-hidden {
  display: none;
  color: var(--secondary-color);
  position: absolute;
}

.why-visible {
  display: block;
  position: absolute;
  padding-right: 50px;
  color: var(--secondary-color);
  -webkit-animation-name: show-why;
          animation-name: show-why;
  -webkit-animation-direction: linear;
          animation-direction: linear;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-transform: translateY(-150px);
          transform: translateY(-150px);
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  letter-spacing: -0.3px;
  position: absolute;

  color: #ffffff;
}

.original-x {
  display: none;
}

.Pricing-price-x {
  cursor: pointer;
  -webkit-animation-name: hide-x;
          animation-name: hide-x;
  -webkit-animation-direction: linear;
          animation-direction: linear;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
}

.Pricing-price-x-show {
  display: block;
  cursor: pointer;
  -webkit-animation-name: show-x;
          animation-name: show-x;
  -webkit-animation-direction: linear;
          animation-direction: linear;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 750ms;
          animation-duration: 750ms;
}

@-webkit-keyframes show-why {
  0% {
    color: var(--secondary-color);
  }
  50% {
    color: var(--secondary-color);
  }
  100% {
    color: white;
  }
}

@keyframes show-why {
  0% {
    color: var(--secondary-color);
  }
  50% {
    color: var(--secondary-color);
  }
  100% {
    color: white;
  }
}
@-webkit-keyframes show-x {
  0% {
    color: var(--secondary-color);
  }
  50% {
    color: var(--secondary-color);
  }
  100% {
    color: white;
  }
}
@keyframes show-x {
  0% {
    color: var(--secondary-color);
  }
  50% {
    color: var(--secondary-color);
  }
  100% {
    color: white;
  }
}
@-webkit-keyframes hide-x {
  0% {
    color: white;
  }
  50% {
    color: var(--secondary-color);
  }
  100% {
    color: var(--secondary-color);
    display: none;
    cursor: unset;
  }
}
@keyframes hide-x {
  0% {
    color: white;
  }
  50% {
    color: var(--secondary-color);
  }
  100% {
    color: var(--secondary-color);
    display: none;
    cursor: unset;
  }
}

.fade-in {
  -webkit-animation: fade-in 500ms 250ms both;
  animation: fade-in 500ms 250ms both;
}

.fade-in2 {
  -webkit-animation: fade-in 500ms 500ms both;
  animation: fade-in 500ms 500ms both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* RESPONSIVENESS */
@media screen and (max-width: 1450px) {
  .Pricing-container {
    width: 90%;
    margin: auto;
  }
  .Pricing-price-content,
  .Pricing-try-content {
    width: auto;
  }

  .Pricing-header {
    color: var(--primary-color);
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
    font-size: 36px;
    font-weight: var(--semi-bold);
    text-align: center;
  }
}

@media screen and (max-width: 1030px) {
  .Pricing-header {
    font-size: 35px;
  }
}

@media screen and (max-width: 1050px) {
  .Pricing-container {
    width: 90%;
    margin: auto;
  }
  .Pricing-information {
    width: 100%;
  }
  .Track-feature-container {
    width: auto;
    flex-direction: column;
    height: auto;
  }

  .Pricing-price,
  .Pricing-try,
  .Pricing-clickable {
    height: 600px;
  }

  .Pricing-Price-content {
    margin: 0px;
  }


}
@media screen and (max-width: 850px) {
  .why-visible {
    -webkit-transform: translateY(-190px);
            transform: translateY(-190px);
    font-size: 15px;

  
    color: #ffffff;
  }
  .move-up {
    -webkit-transform: translateY(-280px);
            transform: translateY(-280px);
  }
}

@media screen and (max-width: 770px) {
  .Pricing-information {
    flex-direction: column;
  }
  .Pricing-price,
  .Pricing-try,
  .Pricing-clickable {
    width: 100%;
    margin: 20px auto;
  }

  .move-up {
    -webkit-transform: translateY(-220px);
            transform: translateY(-220px);
  }
  .why-visible{
    -webkit-transform: translateY(-150px);
            transform: translateY(-150px);
    font-size:18px;

  }
}

/* MOBILE RESPONSIVENESS */
@media screen and (max-width: 550px) {
  .Amount {
    padding-bottom: 0px;
  }
  .x-size {
    font-size: 18px;
  }

  .Pricing-container {
    width: 100%;
    padding-left: 13px;
    padding-right: 13px;
    height: auto;
    letter-spacing: -0.3px;
  }
  .Pricing-information {
    flex-direction: column;
  }
  .Pricing-header {
    width: auto;
    font-size: 26px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.3px;
    padding: 0 30px;
    padding-bottom: 60px;
  }
  .Pricing-price p,
  .Pricing-clickable p {
    font-size: 16px;
    line-height: 24px;
    color: var(--button-text);
  }
  .Pricing-price .Amount,
  .Pricing-clickable .Amount {
    font-size: 36px;
    line-height: 54px;
  }
  .Pricing-price-content {
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin-top: 20px;
  }
  .Pricing-price-why {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 50px;
  }
  .Pricing-price,
  .Pricing-try,
  .Pricing-clickable {
    height: 360px;
    border-radius: 15px;
    padding: 30px 20px;
    margin: 10px 0;
  }
  .Pricing-try p {
    font-size: 16px;
  }
  .Pricing-try-content {
    margin-top: 20px;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .Pricing-try img {
    width: 133px;
  }
  .Pricing-try-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.3px;

    /* text */

    color: #757575;
  }
  .Pricing-Price-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.3px;

    color: #ffffff;
  }

  .why-visible {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    letter-spacing: -0.3px;
    transition: 500ms ease;
    color: #ffffff;
  }

  .move-up {
    -webkit-transform: translateY(-850%);
            transform: translateY(-850%);
  }
  .move-down {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  .why-visible {
    -webkit-transform: translateY(-140px);
            transform: translateY(-140px);
    padding-right: 20px;
    margin-top: 30px;
    font-weight:400;
  }
  .why-container {
    height: 70px;
  }
}

@media screen and (max-width: 510px) {
  .why-visible {
    margin-top: 30px;
  }
}

@media screen and (max-width: 480px) {
  .why-visible {
    margin-top: 0px;
  }
}

@media screen and (max-width: 402px) {
}

@media screen and (max-width: 380px) {
  .Pricing-header {
    width: auto;
    font-size: 24px;

    padding-bottom: 60px;
  }

  .why-visible {
    -webkit-transform: translateY(-170px);
            transform: translateY(-170px);
  }
}

@media screen and (max-width: 360px) {
  .move-up {
    -webkit-transform: translateY(-900%);
            transform: translateY(-900%);
  }

  .why-visible {
    -webkit-transform: translateY(-180px);
            transform: translateY(-180px);
    padding-right: 20px;
    font-size:12px;
  }
}

@media screen and (max-width: 340px) {
  .Pricing-header {
    width: auto;
    font-size: 22px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.3px;
    padding: 0 10px;
    padding-bottom: 40px;
  }
  .why-visible {
    font-size: 13px;
    -webkit-transform: translateY(-190px);
            transform: translateY(-190px);
    padding-right: 15px;
  }
}
@media screen and (max-width: 340px) {

  .why-visible {
    font-size: 12px;
    -webkit-transform: translateY(-210px);
            transform: translateY(-210px);

  }
}

@media screen and (max-width: 300px) {
  .why-visible {
    font-size: 11px;
    -webkit-transform: translateY(-230px);
            transform: translateY(-230px);
    padding-right: 15px;
  }
}

.Ambassadors {
  overflow-x: hidden;
}

.Ambassadors-container {
  width: 1250px;
  margin: auto;
  margin-bottom: 300px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.Ambassadors-main-text {
  font-size: 36px;
  font-weight: var(--semi-bold);
  text-align: left;
  margin-top: 70px;
  width: 350px;
}

.Ambassadors-subText {
  text-align: left;
  width: 600px;
  margin-top: 50px;
  color: var(--darker-grey);
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.3px;
}
.Ambassadors-partnerships {
  color: var(--secondary-color);
  margin-top: 40px;
  text-decoration: underline;
}
.Ambassadors-partnerships:hover {
  cursor: pointer;
}

.Ambassador-images {
  display: grid;
  width: 700px;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "image1 image1 image2 image2 image3 image3 image3"
    "image1 image1 image2 image2 image3 image3 image3"
    "image4 image4 image4 image4 image3 image3 image3"
    "image4 image4 image4 image4 image3 image3 image3";
  grid-gap: 0px;
  gap: 0px;
  margin-top: 50px;
  position: relative;
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
}

.Ambassador-1 {
  grid-area: image1;
  background-color: #c4c4c4;
  width: 206px;
  height: 240px;
  border-radius: 10px;
}
.Ambassador-2 {
  grid-area: image2;
  background-color: #c4c4c4;
  width: 206px;
  height: 240px;
  border-radius: 10px;
}
.Ambassador-3 {
  grid-area: image3;
  background-color: #c4c4c4;
  width: 274px;
  height: 508px;
  border-radius: 10px;
}
.Ambassador-4 {
  grid-area: image4;
  background-color: #c4c4c4;
  width: 425px;
  height: 253.6px;
  margin-right: 10px;
  border-radius: 10px;
}

.Ambassador-images-mobile {
  display: none;
}
.Ambassadors-meet {
  display: none;
}


.Ambassador-1-mobile img,
.Ambassador-2-mobile img,
.Ambassador-3-mobile img,
.Ambassador-4-mobile img
{
  border-radius:10px;
}


@media screen and (max-width: 1450px) {
  .Ambassadors-container {
    width: 90%;
    margin: auto;
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 1250px) {
  .Ambassadors-container {
    flex-direction: column;
  }
  .Ambassadors-mobile-flex {
    width: 100%;
    display: flex;
    height: 600px;
  }
  .Ambassador-images {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }

  .Ambassadors-main-text {
    width: 100%;
    margin: 0px auto;
  }
  .Ambassadors-subText {
    width: 100%;

    margin: 30px auto;
  }

  .Ambassador-images-bigger {
    display: none;
  }
  .bigger {
    display: none;
  }
  .Ambassador-images-mobile {
    display: block;
    width: 96%;

    padding-bottom: 150px;
  }
  .Ambassadors-mobile-flex {
    width: 100%;
    display: flex;
    height: 800px;
  }
  .left-mobile-images {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .right-mobile-images {
    width: 40%;
  }
  .Ambassador-1-mobile {
    grid-area: image1;
    background-color: #c4c4c4;
    width: 48%;
    height: 95%;
    margin-bottom: 5%;
    margin-right: 2%;
    border-radius: 10px;
  }
  .Ambassador-2-mobile {
    grid-area: image2;
    background-color: #c4c4c4;
    width: 48%;
    height: 95%;
    margin-left: 2%;
    margin-bottom: 5%;
    border-radius: 10px;
  }
  .Ambassador-3-mobile {
    grid-area: image3;
    background-color: #c4c4c4;
    width: 106%;
    height: 100%;
    border-radius: 10px;
    margin-left: 5%;
  }
  .Ambassador-4-mobile {
    grid-area: image4;
    background-color: #c4c4c4;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .left-top-images {
    display: flex;
    justify-content: flex-start;
    height: 50%;
  }
  .left-bottom-images {
    height: 50%;
  }
  .right-mobile-images {
    height: 100%;
  }
  .Ambassadors-partnerships {
    text-align: center;
    margin: 40px auto;
  }
}

@media screen and (max-width: 1050px) {
  .Ambassadors-main-text {
    width: auto;
    margin: auto;
  }
  .Ambassadors-mobile-flex {
    height: 600px;
  }
}

@media screen and (max-width: 850px) {
  .Ambassadors-mobile-flex {
    height: 500px;
  }
}

@media screen and (max-width:650px){
  .Ambassadors-mobile-flex {
    height: 400px;
  }
}

@media screen and (max-width: 550px) {
  .Ambassadors-container {
    margin-left: 13px;
    margin-right: 13px;
    flex-direction: column;
    padding-bottom: 250px;
    margin-bottom: 0;
  }
  .Ambassador-images {
    display: block;
  }
  .Ambassadors-main-text {
    width: auto;
    font-size: 20px;
    margin-bottom: 50px;
    text-align: left;
    margin-left: 20px;
  }
  .Ambassadors-subText {
    font-size: 14px;
    width: 90%;
    text-align: left;
    line-height: 21px;
    margin-bottom:50px;
  }

  .Ambassadors-meet {
    display: block;
    text-align: center;
    margin: 20px 0;
    font-size: 20px;
    font-weight: var(--semi-bold);
  }
  .Ambassador-images-bigger {
    display: none;
  }
  .bigger {
    display: none;
  }
  .Ambassador-images-mobile {
    display: block;
    width: 100%;
    padding-bottom: 250px;
  }
  .Ambassadors-mobile-flex {
    height: 270px;
  }
  .Ambassador-images-mobile {
    display: block;
    width: 100%;
    padding-bottom: 25px;
  }
  .Ambassadors-meet {
    margin: 50px auto;
  }
}

@media screen and (max-width: 390px) {
  .Ambassadors-mobile-flex {
    height: 245px;
  }
}

@media screen and (max-width: 360px) {
  .Ambassadors-subText {
    font-size: 12px;
    line-height: 25px;
  }
}

@media screen and (max-width: 320px) {
  .Ambassadors-mobile-flex {
    height: 200px;
  }
}

.cta-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 81px 0 107px;
  width: 1250px;
  height: 360px;
  background-color: #F3FAF6;
  background-image: url(/static/media/Rectangle.370d107d.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 40px;
  overflow: hidden;
  position: relative;
}

.cta-container-text>h1 {
  width: 80%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: -0.3px;
}

.cta-container-img {
  position: relative;
}

/* download icons */
.cta-icons {
  display: flex;
  align-items: center;
  margin-top: 58px;
  text-align: left;
  font-size: 20px;
}

.cta-icons p {
  margin: 0;

}

.cta-icons div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-icons div>a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 15px;
  cursor: pointer;
  transition: all 200ms ease;
  text-decoration: none;
  color: black;
}


.cta-icons div>a:first-child {
  font-size: 30px;
  border: 1.5px solid black;
}

.cta-icons div>a:last-child {
  border: 1.5px solid black;
  font-size: 25px;
}

.cta-icons div a:hover {
  color: white;
}

.cta-icons div>a:hover {
  background-color: black;
}

/* phone img */
.phone-screen {
  position: absolute;
  top: -90px;
  right: 13px;
  width: 238px;
  z-index: 1;
}

.arrow {
  position: absolute;
  right: 400px;
}

.cta {
  padding: 140px 0;
}


/* responsiveness */

@media screen and (max-width: 1250px) {
  .cta {
    width: 90%;
    margin: auto;
    padding: 120px 0;
  }

  .cta-container {
    width: 100%;
    margin: auto;
  }


}

@media screen and (max-width: 1024px) {
  .cta {
    width: 90%;
    margin: auto;
    padding: 120px 0;
  }

  .arrow {
    display: none;
  }
}

@media screen and (max-width: 830px) {
  .cta {
    width: 90%;
    margin: auto;
    padding: 120px 0;
  }

  .cta-container {
    padding: 0 20px 0 40px;
  }

  .cta-icons>p {
    display: none;
  }

  .cta-container {
    /* align-items: flex-start; */
    height: 370px;
    padding: 40px 30px;
    text-align: left;
  }

  .cta-icons div>div {
    margin: 0 15px 0 0;

  }

  .cta-container-text {
    width: 50%;
  }


  .cta-container-img {
    position: static;
    margin: 0 auto;
  }

  .phone-screen {
    width: 230px;
    margin-bottom: -200px;
    position: static;
  }

  .cta-container-text>h1 {
    width: 100%;
    font-size: 45px;
    line-height: 60px;
  }

  .cta-icons {
    margin-top: 15px;

  }


}

@media screen and (max-width: 550px) {
  .phone-screen {
    width: 150px;
    margin-bottom: -40px;
    position: static;
  }

  .cta-container {
    flex-direction: column;
    width: 100%;
    height: 430px;
  }

  .cta-container-text {
    width: 100%;
  }

  .cta-container-text>h1 {
    width: 100%;
    margin-bottom: 26px;
    font-size: 24px;
    line-height: 40px;
  }

  .cta-icons {
    margin-top: 0;

  }

  .phone-screen {
    width: 150px;
    margin-bottom: -50px;
    position: static;
  }

  .cta-icons div>div {
    width: 40px;
    height: 40px;
    margin: 0 9px;
  }

  .arrow {
    width: 40px;
    display: block;
    position: absolute;
    bottom: 140px;
    left: 50px;
  }
}

@media screen and (max-width:375px) {
  .arrow {
    display: none;
  }
}
.footer {
  background-color: #121212;

  /* z-index:10;
    position:relative; */
}
.footer-container {
  width: 1250px;
  margin: 0 auto;
  padding: 135px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.footer-container h2 {
  color: white;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  padding-bottom: 24px;
}
.footer-container a {
  display: block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.3px;
  color: #9e9e9e;
  padding-bottom: 8px;
  transition: all 200ms ease;
}
.footer-container p {
  cursor: pointer;
  display: block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.3px;
  color: #9e9e9e;
  padding-bottom: 8px;
  transition: all 200ms ease;
}
.flex {
  display: flex;
}

.footer-link:hover {
  color: white;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.footer-icon {
  cursor: pointer;
  font-size: 31px;
  color: #fafafa;
  margin: 40px 28px 0 0;
}
/* .footer-icon:hover{
  color: black;  
} */

.other {
  display: flex;
  grid-gap: 127px;
  gap: 127px;
}
.other ul {
  list-style: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

/* responsive */
@media screen and (max-width: 1250px) {
  .footer-container {
    max-width: 1000px;
  }
  .other {
    grid-gap: 70px;
    gap: 70px;
  }
}
@media screen and (max-width: 1024px) {
  .footer-container {
    width: 90%;
    padding: 135px 0;
  }
  .other {
    grid-gap: 40px;
    gap: 40px;
  }
}
@media screen and (max-width: 768px) {
  .footer-container {
    width: 100%;
    margin: 0px 34px;
    flex-direction: column;
    text-align: left;
    padding: 60px 0;
  }
  .footer-container h2 {
    padding-bottom: 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #fafafa;
  }
  .support-mail-text {
    text-decoration: underline;
  }

  .footer-container a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #9e9e9e;
  }

  .other {
    padding-top: 60px;
    flex-direction: column;
    margin: 0;
  }
}

* {
  margin: 0;
}

:root {
  --anim-end: -2600px;
  /* 1400 */
}
:root {
  --anim-end-mobile: -6400px;
  /* 1400 */
}

.Testimonials {
  background-color: var(--light-grey-bg);
  height: 700px;
  margin-top: -150px;
  overflow-y: hidden;
  position: relative;
}
.Testimonials-container {
  width: 1250px;
  margin: auto;
  position: relative;
}
.Testimonials-header h3 {
  color: var(--primary-color);
  margin: auto;
  -webkit-transform: translateY(-250px);
          transform: translateY(-250px);
  text-align: center;
  width: 100vw;
  font-size: 36px;
  font-weight: var(--semi-bold);
  width: 1250px;
  line-height: 54px;
}

.Testimonial::before {
  content: "";
  z-index: 99;
  position: absolute;
  width: 100%;
  /* height:710px; */
  height: 100px;
  margin-top: -5px;

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */
  background: -o-linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#F8F8F8', GradientType=1 );

  /* ie 10+ */

  /* global 94%+ browsers support */
  background: linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.overlay-top {
  width: 100%;
  height: 100px;
  z-index: 100;
  position: absolute;
  top: 0px;
  /* ff 3.6+ */

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */
  background: -o-linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#F8F8F8', GradientType=1 );

  /* ie 10+ */

  /* global 94%+ browsers support */
  background: linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}
.overlay-bottom {
  width: 100%;
  height: 100px;
  z-index: 100;
  position: absolute;
  bottom: 0px;
  /* ff 3.6+ */

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */
  background: -o-linear-gradient(
    0deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F8F8F8', endColorstr='#FFFFFF', GradientType=1 );

  /* ie 10+ */

  /* global 94%+ browsers support */
  background: linear-gradient(
    0deg,
    rgba(248, 248, 248, 1) 0%,
    rgba(248, 248, 248, 0.44) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.Testimonial-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  overflow-x: hidden;
}

.TestimonialsLeft,
.TestimonialsMiddle,
.TestimonialsRight,
.TestimonialsMobile {
  -webkit-animation-name: testimonials;
          animation-name: testimonials;
  -webkit-animation-duration: 55s;
          animation-duration: 55s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: linear;
          animation-direction: linear;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  margin: 0 5px;
}

.TestimonialsMobile{
  display:none;
}

.Testimonial-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 20px;
  padding: 0px;

  margin: 40px 0px;
  color: #000;
}



.Testimonial-text {
  margin-top: 20px;
}
.Testimonial-user-info {
  margin-top: -10px;
}

/* ANIMATION KEYFRAMES */

@-webkit-keyframes testimonials {
  to {
    -webkit-transform: translateY(-2600px);
            transform: translateY(-2600px);
    -webkit-transform: translateY(var(--anim-end));
            transform: translateY(var(--anim-end));
  }
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes testimonials {
  to {
    -webkit-transform: translateY(-2600px);
            transform: translateY(-2600px);
    -webkit-transform: translateY(var(--anim-end));
            transform: translateY(var(--anim-end));
  }
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

/* RESPONSIVENESS */

@media screen and (max-width: 1250px) {
  .Testimonials-container {
    width: 1000px;
    margin: auto;
  }
  .Testimonials-header h3 {
    width: 1000px;
  }
}

@media screen and (max-width: 1050px) {
  .Testimonials-container {
    width: 90%;
    margin: auto;
  }
  .Testimonials-header h3 {
    width: 90%;
  }
  .Testimonial-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .Testimonial-div {
    margin: 30px 0px;

  }
  .TestimonialsRight{
    display:none;
  }
}

/* MOBILE RESPONSIVENESS */

@media screen and (max-width: 650px) {
  .Testimonial-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .Testimonial-div {
    margin: 20px 0px;

  }
}
@media screen and (max-width: 550px) {
  .TestimonialsLeft,
  .TestimonialsMiddle,
  .TestimonialsRight {
    -webkit-animation-duration: 20s;
            animation-duration: 20s;
  }

  .TestimonialsMiddle {
    display: none;
  }
  .TestimonialsRight {
    display: none;
  }
  .TestimonialsLeft{
    display:none;
  }
  .TestimonialsMobile{
    display:block;
    -webkit-animation-duration: 150s;
            animation-duration: 150s;
  }

  .Testimonials-container {
    flex-direction: column;
    width: 90%;
    height: auto;
  }
  .Testimonial-header {
    margin: auto;
  }
  .Testimonials-header h3 {
    width: 90%;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.3px;
    font-weight: var(--semi-bold);
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
  }
  .Testimonial-user-name,
  .Testimonial-user-handle,
  .Testimonial-text {
    color: #000;
  }

  @-webkit-keyframes testimonials {
    to {
      -webkit-transform: translateY(-6400px);
              transform: translateY(-6400px);
      -webkit-transform: translateY(var(--anim-end-mobile));
              transform: translateY(var(--anim-end-mobile));
    }
    from {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }

  @keyframes testimonials {
    to {
      -webkit-transform: translateY(-6400px);
              transform: translateY(-6400px);
      -webkit-transform: translateY(var(--anim-end-mobile));
              transform: translateY(var(--anim-end-mobile));
    }
    from {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
}

@media screen and (max-width: 350px) {
  .Testimonial-user-details {
    flex-wrap: wrap;
  }
  .Testimonial-div {
    width: 280px;
  }
}
@media screen and (max-width: 310px) {
  .Testimonial-div {
    width: 240px;
  }
}

h1 {
  color: #121212;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
}
.about-hero {
  background-color: var(--primary-color);
  color: var(--button-text);
}
.about-hero-container {
  width: 1250px;
  height: 1000px;
  margin: 0 auto;
  padding-top: 140px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

.about-hero-text {
  padding-top: 140px;
  width: 50%;
}
.about-hero-main-text {
  width: 648px;
  height: 162px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -0.3px;
  color: #fafafa;
  padding-bottom: 36px;
}
.about-hero-subtext {
  width: 666px;
  height: 352px;
  padding-top: 36px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: -0.3px;
  color: #9e9e9e;
}
.about-hero-textspace {
  margin: 20px 0;
}

.about-hero-image {
  align-self: center;
  position: relative;
  width: 471px;
  height: 473px;

  z-index: 2;
}

.about-hero-image > img {
  border-radius: 10px;
}
.about-download-icons {
  display: none;
  margin-top: 58px;
  text-align: left;
  font-size: 20px;
}
.about-download-icons div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-download-icons div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 15px;
  cursor: pointer;
  transition: all 200ms ease;
}
.about-download-icons div > div:first-child {
  border: 1.5px solid white;
  font-size: 30px;
}

.about-download-icons div > div:last-child {
  border: 1.5px solid white;
  font-size: 25px;
}
.about-download-icons div > div:hover {
  color: black;
}
.about-download-icons div > div:hover {
  background-color: white;
}

/* about-info section */
.about-info {
  width: 1250px;
  margin: 0 auto;
  padding: 140px 0;
  text-align: center;
}
.about-info > p {
  color: #9e9e9e;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  padding: 0 220px;
  padding-top: 12px;
}
.about-info-container {
  padding-top: 110px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 48px;
  gap: 48px;
}
.about-info-container-inner {
  text-align: left;
}

.about-info-container-inner > div {
  width: 80px;
  height: 80px;
  background: #f4f4f4;
  border-radius: 10px;
}
.about-info-container-inner > h3 {
  color: #121212;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 34px */
  padding-top: 24px;
  letter-spacing: -0.3px;
}
.about-info-container-inner > p {
  color: #9e9e9e;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  padding-top: 12px;
  margin-bottom: 60px;
}

/* about minds section */
.about-minds {
  background-color: #f8f8f8;
  text-align: center;
}
.about-minds-container {
  margin: 0 auto;
  padding: 96px 0;
  max-width: 1250px;
}
.about-minds-container > p {
  color: #9e9e9e;
  /* width: 827px; */
  font-size: 19px;
  font-weight: 400;
  padding: 24px 13% 60px 13%;
  margin: 0 auto;
}
.about-minds-img-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  gap: 40px;
  margin: 0 auto;
}

.about-minds-img-container > div {
  width: 200px;
  height: 200px;

  margin: 0 auto;
  margin-bottom: 80px;
}

.image {
  position: relative;
}

.image__img {
  display: block;
  width: 100%;
  border-radius: 50%;
  margin-bottom: 25px;
}

.image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.image__overlay--blur {
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.image__overlay--primary {
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.75));
}

.image__overlay > * {
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.image__overlay:hover {
  opacity: 1;
}

.image__overlay:hover > * {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.image__title {
  text-align: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;

  line-height: 21px;
  letter-spacing: -0.3px;
  margin-bottom: 4px;
}

.image__description {
  text-align: center;

  font-family: 'Poppins';
  font-style: normal;
  margin-top: 2px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: -0.3px;
  color: #757575;
}

/* Responsive */

@media screen and (max-width: 1450px) {
  .about-hero-container {
    width: 90%;
  }

  .about-hero {
  }

  .about-hero-main-text {
    width: 100%;
    height: auto;
  }
  .about-hero-subtext {
    padding-top: 0;
    width: 100%;
    height: auto;
  }
  .about-info {
    width: 90%;
    padding: 100px 0;
  }
}

@media screen and (max-width: 1050px) {
  .about-hero-container {
    flex-direction: column;
    width: 90%;
    margin: auto;
    height: auto;
    padding-top: 100px;
  }
  .about-hero-image {
    display: none;
  }
  .about-hero-text {
    width: 100%;
    padding-top: 100px;
  }

  .about-minds-img-container {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    gap: 24px;
  }

  .about-info > p {
    padding: 0px;
  }
}

@media screen and (max-width: 768px) {
  .about-hero {
    margin: 0;
    height: auto;
  }
  .about-hero-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .about-hero-text {
    padding-top: 50px;
    width: 50%;
  }

  .about-hero-text {
    width: 100%;
  }
  .about-hero-subtext {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
  }

  .about-download-icons div > div:first-child {
    border: 1.5px solid white;
    font-size: 25px;
  }

  .about-download-icons div > div:last-child {
    border: 1.5px solid white;
    font-size: 20px;
  }

  .about-info {
    width: 90%;
    padding: 100px 0;
  }
  .about-info > h1 {
    font-size: 24px;
  }

  .about-info-container {
    padding-top: 60px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* gap: 10px;     */
    width: 100%;
  }

  .about-info-container-inner > h3 {
    font-size: 20px;
    line-height: 140%;
    padding-top: 24px;
    letter-spacing: -0.3px;
  }

  .about-info-container-inner > p {
    font-size: 14px;
    line-height: 21px;
    padding-top: 7px;
    margin-bottom: 40px;
  }
  .about-minds-container {
    width: 90%;
    margin: 0 auto;
  }

  .about-minds-container > h1 {
    text-align: left;
    font-size: 24px;
  }

  .about-minds-container > p {
    color: #9e9e9e;
    text-align: left;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    padding: 24px 0;
    padding-bottom: 36px;
  }

  .about-minds-img-container {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    min-width: 100%;
  }

  .about-minds-img-container > div {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 90px;
  }

  .image__img {
    margin-bottom: 20px;
  }

  .image__title {
    font-size: 16px;
  }

  .image__description {
    font-size: 14px;
  }
}

@media screen and (max-width: 620px) {
  .about-minds-img-container {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 550px) {
  .about-hero-container {
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .about-hero {
    padding-bottom: 0;
  }

  .about-hero-main-text {
    font-size: 30px;
    line-height: 43px;
  }
  .about-hero-subtext {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #9e9e9e;
  }
  .about-download-icons {
    font-size: 16px;
  }
  .about-download-icons div > div {
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }

  .about-hero-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
  }

  .about-hero-img {
    margin: 0 auto;
    width: 471px;
  }
  .about-info {
    width: 85%;
  }
  .about-info > h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .about-info > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    padding: 0px;
    letter-spacing: -0.3px;
    color: #757575;
  }
  .about-info-container-inner > h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #121212;
    padding-top: 24px;
  }

  .about-info-container-inner > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #757575;
    padding-top: 7px;
  }

  .about-info-container {
    padding-top: 40px;
  }
  .about-minds-container {
    width: 90%;
    margin: auto;
  }
  .about-minds-container > h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #121212;
  }

  .about-minds-container > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #757575;
    padding: 24px 0;
    padding-bottom: 36px;
  }
}

@media screen and (max-width: 380px) {
  .about-hero {
    padding-bottom: 0;
  }

  .about-hero-main-text {
    font-size: 20px;
    line-height: 35px;
  }
  .about-hero-subtext {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #9e9e9e;
  }
  .about-download-icons {
    font-size: 16px;
  }
  .about-download-icons div > div {
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }

  .about-hero-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    height: auto;
  }

  .about-hero-image img {
    margin: 0 auto;
    width: 100%;
  }
  .about-info > h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .about-info > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    padding: 0px;
    letter-spacing: -0.3px;
    color: #757575;
  }

  .about-info-container-inner > h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #121212;
    padding-top: 24px;
  }

  .about-info-container-inner > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #757575;
    padding-top: 7px;
  }

  .about-info-container {
    padding-top: 40px;
  }
  .about-minds-container > h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #121212;
  }

  .about-minds-container > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #757575;
    padding: 24px 0;
    padding-bottom: 36px;
  }

  .about-minds-img-container > div {
    width: 150px;
    height: 150px;
  }
  .image__title {
    top: 104px;
    font-size: 15px;
  }

  .image__description {
    top: 120px;
    font-size: 13px;
  }
}

@media screen and (max-width: 350px) {
  .about-minds-img-container > div {
    width: 135px;
    height: 135px;
  }

  .image__title {
    top: 85px;
    font-size: 15px;
  }

  .image__description {
    top: 102px;
    font-size: 13px;
  }
}

.help-hero {
  background-color: var(--primary-color);
}
.help-hero-container {
  width: 1250px;
  margin: auto;
  display: flex;
  height: 1000px;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 100px;
}
.help-hero-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #9e9e9e;
  text-align: center;
  height: 384px;
  width: 837px;
  margin: 0 auto;
  padding-top: 200px;
}
.help-hero-text h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #fafafa;
  width: 547px;
  margin: 0 auto;
  padding-bottom: 25px;
}

.help-hero-text p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.3px;
}
.help-hero-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.help-hero-icons div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin: 60px 36px 0 0;
  border: 1.5px solid white;
  border-radius: 50%;
}
.help-hero-icon {
  font-size: 31px;
  color: #fafafa;
  align-self: center;
}
.help-hero-icons div:hover .help-hero-icon {
  color: black;
  transition: all 200ms ease;
}
.help-hero-icons div:hover {
  background-color: white;
  transition: all 200ms ease;
}

.help-faq-container {
  max-width: 1250px;
  margin: 0 auto;
  padding: 140px 0;
}
.help-faq-container-text {
  width: 858px;
  margin: 0 auto;
  text-align: center;
}
.help-faq-container-text h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -0.3px;
  color: #121212;
  padding-bottom: 24px;
}
.help-faq-container-text-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.3px;
  color: #9e9e9e;
  padding-bottom: 50px;
}
.accordion-label-container {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  cursor: pointer;
}
.mantine-Accordion-label {
  /* width: 341px; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: black;
  /* padding: 7px 0; */
}
.mantine-Accordion-contentInner {
  width: 867px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #757575;
  transition: all 200ms ease;
}

.slide-in-top {
  -webkit-animation: slide-in-top 400ms ease both;
          animation: slide-in-top 400ms ease both;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

/* @keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
} */

/* Responsive */

@media screen and (max-width: 1450px) {
  .help-hero-container {
    width: 90%;
    margin: auto;
  }

  .help-hero-text {
    height: auto;
    margin: 0 auto;
  }
  .help-hero-text h1 {
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    width: 547px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1050px) {
  .help-hero-text {
    text-align: center;
    height: auto;
    width: 100%;
    margin: 0 auto;
  }

  .help-hero-text h1 {
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    width: 547px;
    margin: 0 auto;
  }

  .help-hero-icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .help-hero-icons div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin: 48px 36px 0 0;
    border: 1.5px solid white;
    border-radius: 50%;
  }

  .help-hero-icon {
    font-size: 31px;
    color: #fafafa;
    align-self: center;
  }

  .help-hero-icons div:hover .help-hero-icon {
    color: black;
  }

  .help-hero-icons div:hover {
    background-color: white;
  }

  .help-faq-container {
    width: 90%;
    margin: 0 auto;
    padding: 140px 0;
  }

  .help-faq-container-text {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .help-faq-container-text h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    letter-spacing: -0.3px;
    color: #121212;
    padding-bottom: 24px;
  }

  .help-faq-container-text p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.3px;
    color: #9e9e9e;
    padding-bottom: 50px;
  }

  .mantine-Accordion-label {
    width: 341px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.3px;

    padding: 7px 0;
  }

  .mantine-Accordion-contentInner {
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.3px;
  }
}

@media screen and (max-width: 768px) {
  .help-hero-container {
    max-width: 1250px;
    height: auto;
    margin: 0 auto;
  }
  .help-hero-text {
    padding-top: 100px;
  }
  .help-hero-text h1 {
    font-size: 32px;
    line-height: 48px;
    width: 90%;
  }
  .help-hero-text p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .help-hero-icons div {
    width: 60px;
    height: 60px;
    margin: 40px 10px 0 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }

  .help-faq-container {
    width: 90%;
    margin: 0 auto;
    padding: 80px 0;
  }
  .help-faq-container-text h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.3px;
    color: #121212;
    padding-bottom: 18px;
  }

  .help-faq-container-text p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.3px;

    color: #757575;
    padding-bottom: 35px;
  }
  .accordion-label-container {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
  }

  .mantine-Accordion-label {
    width: 100%;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.3px;
    color: black;
    padding: 7px 0;
  }

  .mantine-Accordion-contentInner {
    width: 100%;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.3px;
    color: #757575;
  }
}

@media screen and (max-width: 550px) {
  .help-hero-text {
    padding-top: 100px;
  }
  .help-hero-text h1 {
    font-size: 30px;
  }

  .help-hero-text p {
    font-size: 12px;
    line-height: 24px;
    text-align: center;
  }
  .help-hero-icons div {
    width: 50px;
    height: 50px;
  }

  .help-hero-icons div {
    margin-top: 25px;
  }

  .help-hero-icons div svg {
    font-size: 25px;
  }

  .help-faq-container-text h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.3px;
    color: #121212;
    padding-bottom: 18px;
  }

  .help-faq-container-text p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #9e9e9e;
    padding-bottom: 35px;
  }

  .mantine-Accordion-label {
    width: 100%;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.3px;

    padding: 7px 0;
  }

  .mantine-Accordion-contentInner {
    width: 100%;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.3px;
  }
}

@media screen and (max-height: 700px) {
  .help-hero-text {
    padding-top: 100px;
  }
  .help-hero-container {
    padding-bottom: 100px;
  }
}


.HomePage{
    overflow-x:hidden;
}


