.Hero {
  background-color: var(--primary-color);
  color: var(--button-text);
}

.Hero-container {
  width: 1250px;
  margin: auto;
  display: flex;
  height: 1000px;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 150px;
}

.Hero-download-apple-logo {
  margin-left: 0px !important;
}

.Hero-download-icons {
  cursor: pointer;
}



.Hero-download-icons div > div:first-child {
  border: 1.5px solid white;
  font-size: 30px;
}

.Hero-download-icons div > div:last-child {
  border: 1.5px solid white;
  font-size: 25px;
}
.Hero-download-icons div > div:hover {
  color: black;
}
.Hero-download-icons div > div:hover {
  background-color: white;
}

/* HERO LEFT HALF */

.Hero-text {
  width: 50%;
  margin-top: 200px;
  font-style: normal;
}
.Hero-main-text {
  font-size: 60px;
  text-align: left;
  font-style: normal;
}
.Hero-subtext {
  text-align: left;
  font-size: 18px;
  font-weight: var(--regular);
  color: var(--regular-text);
  margin-top: 50px;
  width: 698px;
}

.Hero-download {
  font-size: 20px;
  color: var(--button-text);
  text-align: left;
  margin-top: 50px;
  font-weight: var(--medium);
}

.Hero-download {
  display: flex;
  align-items: center;
}
.Hero-download-text {
  margin: 0px;
  margin-right: 24px;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
}

.Hero-download-icons {
  display: flex;
  text-align: left;
  font-size: 20px;
}
.Hero-download-icons > div {
  transition: all 200ms ease;
}

.Hero-download-icons div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Hero-download-icons div>a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  
  cursor: pointer;
  transition: all 200ms ease;
  text-decoration: none;
  color: white;
}


.Hero-download-icons div>a:first-child {
  font-size: 30px;
  border: 1.5px solid white;
  margin-left:0px;
}

.Hero-download-icons div>a:last-child {
  border: 1.5px solid white;
  font-size: 25px;
  margin-left: 25px;
}

.Hero-download-icons div a:hover {
  color: black;
}

.Hero-download-icons div>a:hover {
  background-color: white;
}
/* .Hero-download-flex{
    justify-content: flex-start;
    align-items: flex-start;
  } */

/* HERO RIGHT HALF (PHONE IMG)*/

.Hero-image {
  position: relative;
  margin-top: 140px;
}
.phoneScreen {
  position: absolute;
  left: 12px;
  top: 12px;
}
.mobile {
  display: none;
}

/* RESPONSIVENESS */

@media screen and (max-width: 1450px) {
  .Hero-container {
    width: 90%;
    margin: auto;
  }
  .Hero-text {
    font-style: normal;
  }
  .Hero-container {
    height: auto;
  }
}

@media screen and (max-width: 1250px) {
}

@media screen and (max-width: 1050px) {
  .Hero-image {
    display: none;
  }
  .Hero-text {
    margin-top: 100px;
    
    font-style: normal;
    width: 100%;
  }
  .Hero-container{
    padding-bottom:100px;
  }
}

@media screen and (max-width: 770px) {
  .Hero-text {
    margin: 0;
    padding-top: 50px;
  }

  .Hero-main-text {
    font-size: 50px;
  }

  .Hero-subtext {
    font-size: 16px;
  }
}

/* MOBILE RESPONSIVENESS */
@media screen and (max-width: 550px) {
  .Hero-container {
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    padding: 0px 32px;
    padding-top: 50px;
    height: auto;
    min-height: 1000px;
    padding-bottom: 75px;
  }

  .Hero-main-text {
    line-height: 48px;
    font-size: 32px;
    padding-right: 20px;
  }
  .Hero-text {
    width: 90%;

    font-style: normal;
  }
  .Hero-subtext {
    font-size: 14px;
    line-height: 21px;
    margin-top: 36px;
    font-weight: 400;
    width: 100%;
  }
  .Hero-download {
    font-size: 16px;
    margin-top: 44px;
  }
  .Hero-download-icons div > div {
    width: 40px;
    height: 40px;
  }

  .Hero-download-text {
    display: none;
  }
  .Hero-download-icons {
    font-size: 16px;
  }
  .Hero-download-icons div > div:first-child {
    border: 1.5px solid white;
    font-size: 25px;
  }

  .Hero-download-icons div > div:last-child {
    border: 1.5px solid white;
    font-size: 20px;
  }
  .Hero-image {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 96px;
  }
  .desktop {
    display: none;
  }
  .mobile img {
    /* transform:translateX(-10px); */
  }
  .Hero-download-icons {
    font-size: 16px;
  }
  .Hero-download-icons div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .Hero-download-icons div > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* margin: 0 15px; */
    margin: 0;
    margin-left: 30px;
    transition: all 200ms ease;
  }

  .Hero-download-icons div > div:first-child {
    border: 1.5px solid white;
    font-size: 25px;
  }

  .Hero-download-icons div > div:last-child {
    border: 1.5px solid white;
    font-size: 20px;
  }
  .Hero-image {
    margin-top: 104px;
    transform: scale(1.2);
  }
}

@media screen and (max-width: 400px) {
  .Hero-main-text {
    font-size: 30px;
  }
  .Hero-subtext {
    font-size: 13px;
  }

  .Hero-image {
    margin-top: 50px;
    transform: scale(1);
  }
}

@media screen and (max-width: 330px) {
  .Hero-main-text {
    font-size: 24px;
    line-height: 34px;
  }
  .Hero-subtext {
    font-size: 12px;
    width: 110%;
  }

  .Hero-image {
    margin-top: 50px;

  }
}
