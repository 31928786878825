.footer {
  background-color: #121212;

  /* z-index:10;
    position:relative; */
}
.footer-container {
  width: 1250px;
  margin: 0 auto;
  padding: 135px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.footer-container h2 {
  color: white;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  padding-bottom: 24px;
}
.footer-container a {
  display: block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.3px;
  color: #9e9e9e;
  padding-bottom: 8px;
  transition: all 200ms ease;
}
.footer-container p {
  cursor: pointer;
  display: block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.3px;
  color: #9e9e9e;
  padding-bottom: 8px;
  transition: all 200ms ease;
}
.flex {
  display: flex;
}

.footer-link:hover {
  color: white;
  transform: scale(1.1);
}

.footer-icon {
  cursor: pointer;
  font-size: 31px;
  color: #fafafa;
  margin: 40px 28px 0 0;
}
/* .footer-icon:hover{
  color: black;  
} */

.other {
  display: flex;
  gap: 127px;
}
.other ul {
  list-style: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

/* responsive */
@media screen and (max-width: 1250px) {
  .footer-container {
    max-width: 1000px;
  }
  .other {
    gap: 70px;
  }
}
@media screen and (max-width: 1024px) {
  .footer-container {
    width: 90%;
    padding: 135px 0;
  }
  .other {
    gap: 40px;
  }
}
@media screen and (max-width: 768px) {
  .footer-container {
    width: 100%;
    margin: 0px 34px;
    flex-direction: column;
    text-align: left;
    padding: 60px 0;
  }
  .footer-container h2 {
    padding-bottom: 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #fafafa;
  }
  .support-mail-text {
    text-decoration: underline;
  }

  .footer-container a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #9e9e9e;
  }

  .other {
    padding-top: 60px;
    flex-direction: column;
    margin: 0;
  }
}
